<div class="container settings-page">
    <header class="pageHeader">
        <h3><i class="fa fa-cog fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.Settings' | translate}}</h3>
    </header>

    <div [@fadeInOut]>
        <div class="d-sm-flex flex-row">
            <ul appBootstrapTab #tab="bootstrap-tab" (showBSTab)="onShowTab($event)" class="nav nav-tabs nav-tabs--vertical nav-tabs--left" role="navigation">
                <li class="nav-item">
                    <a id="profileTab" [routerLink]="[]" fragment="profile" data-target="#profile" href="#profile" class="nav-link active" data-toggle="tab" role="tab" aria-controls="profile">
                        <i class="fa fa-user-circle-o fa-fw" aria-hidden="true"></i> {{'settings.tab.Profile' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a id="preferencesTab" [routerLink]="[]" fragment="preferences" data-target="#preferences" href="#preferences" class="nav-link" data-toggle="tab" role="tab" aria-controls="preferences">
                        <i class="fa fa-sliders fa-fw" aria-hidden="true"></i> {{'settings.tab.Preferences' | translate}}
                    </a>
                </li>
            </ul>
            <div class="tab-content w-100">
                <div class="tab-pane show active" id="profile" role="tabpanel">
                    <h4>{{'settings.header.UserProfile' | translate}}</h4>
                    <hr class="separator-hr" />
                    <div [@fadeInOut] *ngIf="isProfileActivated" class="content-container pl-lg-2">
                        <app-user-info></app-user-info>
                    </div>
                </div>
                <div class="tab-pane" id="preferences" role="tabpanel">
                    <h4>{{'settings.header.UserPreferences' | translate}}</h4>
                    <hr class="separator-hr" />
                    <div [@fadeInOut] *ngIf="isPreferencesActivated" class="content-container pl-lg-2">
                        <app-user-preferences></app-user-preferences>
                    </div>
                </div>              
            </div>
        </div>
    </div>
</div>
