
<div class="row control-box">
    <div class="col-lg-8">
    </div>
    <div class="col-lg-4">
        <ul class="nav flex-column flex-lg-row float-right">
            <li class="nav-item toolbaritem">
                <a class="nav-link" href="javascript:;" (click)="addPhase()"><i class="fa fa-plus"></i> {{'phase.AddPhase' | translate}}</a>
            </li>
        </ul>
    </div>
</div>
<ngx-datatable class="material colored-header sm table-hover"
               [columnMode]="'force'"
               [columns]="phaseColumns"
               [headerHeight]="35"
               [footerHeight]="35"
               rowHeight="auto"
               [rows]="phaseRows"
               [count]="phaseTotalCount"
               [limit]="20">
</ngx-datatable>


<ng-template #daneTemplate let-row="row" let-value="value">
    <span class="inline-label">
        {{value}}
    </span>
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
    <div class="text-center">
        <a (click)="deletePhase(value)" href="javascript:;"><i class="fa fa-trash"></i></a>
    </div>
</ng-template>

<div class="modal fade" bsModal #phaseEditorModal="bs-modal" tabindex="-1" >
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title float-left"><i class="fa fa-tasks"></i> {{'phase.AddPhase' | translate}}</h4>
                <button type="button" class="close" title="Close" (click)="phaseEditorModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form *ngIf="formResetToggle" name="phaseEditorForm" #f="ngForm" novalidate  autocomplete="off" (ngSubmit)="f.form.valid ? savePhase() :  (!phaseName.valid && showErrorAlert('Phase Name is required', 'Please enter a Phase Name.')); ">
                    <div class="form-group row">
                        <label class="col-form-label col-md-3" for="competitionId">{{'phase.Competition' | translate}}</label>
                        <div class="col-md-9">
                            <select class="form-control" attr.id="competitionId" name="competitionId" placeholder="Select competition" required [(ngModel)]="newPhase.tournamentCompetitionId" #competitionId="ngModel" [ngClass]="{'is-valid': f.submitted && competitionId.valid, 'is-invalid' : f.submitted && !competitionId.valid}">
                                <option *ngFor="let competition of tournamentCompetition" [value]="competition.id">
                                    {{competition.competitionName}}
                                </option>
                            </select>
                            <span *ngIf="f.submitted && !competitionId.valid" class="invalid-feedback">
                                {{'phase.CompetitionRequired' | translate}}
                            </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-form-label col-md-3" for="phaseName">{{'phase.Phase' | translate}}:</label>
                        <div class="col-md-9">
                            <input type="text" id="phaseName" name="phaseName" placeholder="Enter  Phase Name" class="form-control" [ngClass]="{'is-valid': f.submitted && phaseName.valid, 'is-invalid' : f.submitted && !phaseName.valid}" [(ngModel)]="newPhase.phaseName" #phaseName="ngModel" required />
                            <span *ngIf="f.submitted && !phaseName.valid" class="invalid-feedback">
                                {{'phase.PhaseRequired' | translate}}
                            </span>
                        </div>
                    </div>
                    <div class="form-group actionBtn-form-group">
                        <div class="float-right">
                            <button type="submit" class="btn btn-primary">{{'phase.AddPhase' | translate}}</button>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
    </div>
</div>

