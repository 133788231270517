import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { Utilities } from '../../../../services/utilities';
import { NewTournamentApplication } from '../../../../models/new-tournament-application.model';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInOut } from '../../../../services/animations';
import { Competition } from '../../../../models/Competition.model';
import { TournamentService } from '../../../../services/tournament.service';
import { Player } from '../../../../models/player.model';
import { Tournament } from '../../../../models/tournament.model';
import { TournamentCompetition } from '../../../../models/tournament-competitions.model';
import { ClubDiscipline } from '../../../../models/club-discipline.model';


@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss'],
    animations: [fadeInOut]
})

export class ApplicationComponent implements OnInit {

    tournament: Tournament = new Tournament();
    application: NewTournamentApplication = new NewTournamentApplication();

    disciplineClubs: ClubDiscipline[];
    userPlayers: Player[];
    competitions: TournamentCompetition[];




    public isSended = false;
    public showValidationErrors = false;
    public formResetToggle = true;

    @ViewChild('f')
    public form;

    @ViewChild('playerName')
    public playerName;

    @ViewChild('dateOfBirth')
    public dateOfBirth;

    @ViewChild('clubName')
    public clubName;

    @ViewChild('tournamentCompetitionIds')
    public tournamentCompetitionIds;




    constructor(private router: Router, private alertService: AlertService, private tournamentService: TournamentService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.getData();
    }



    getData() {
        this.alertService.startLoadingMessage();
        this.loadTournament();
        this.getPlayers();
        this.showValidationErrors = true;
        this.alertService.stopLoadingMessage();
    }

    private loadTournament() {
        const id = Number(this.route.snapshot.paramMap.get('id'));
        this.tournamentService.getTournamentById(id).subscribe(tournament => {
            this.tournament = tournament;
            this.application.disciplineId = tournament.disciplineId;
            this.competitions = tournament.tournamentCompetitions;
            this.getClubs();
        }, error => this.onFailed(error));
    }

    getPlayers() {
        this.tournamentService.getUserPlayers().subscribe(players => this.userPlayers = players, error => this.onFailed(error));
    }

    getClubs() {
        this.tournamentService.getDisciplineClubs(this.tournament.disciplineId).subscribe(clubs => this.disciplineClubs = clubs, error => this.onFailed(error));
    }






    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }

    send() {
        this.alertService.startLoadingMessage('Saving changes...');
        this.tournamentService.createApplication(this.application).subscribe(a => this.sendSuccessHelper(), error => this.onFailed(error));
    }

    private sendSuccessHelper() {
        this.isSended = true;
        this.alertService.stopLoadingMessage();
        this.showValidationErrors = false;
        this.alertService.showMessage('Success', `${this.application.playerName} wpisany na zawody`, MessageSeverity.success);
        this.resetForm();
    }








    playerSelected(result) {
        this.application.playerId = result.item.id;
        this.application.playerName = result.item.playerName;
        this.application.dateOfBirth = result.item.dateOfBirth;
        let ccc = this.disciplineClubs.find(a => a.id == result.item.lastClubDisciplineId);
        if (ccc != null) {
            this.application.clubDisciplineId = ccc.id;
            this.application.clubName = ccc.clubName;
        }
    }




    clubSelected(result) {
        this.application.clubDisciplineId = result.item.id;
        this.application.clubName = result.item.clubName;
    }


    playerNoResults(result) {
        this.application.playerId = null;
        this.application.dateOfBirth = null;
        this.application.clubDisciplineId = null;
        this.application.clubName = null;
    }

    clubNoResults(result) {
        this.application.clubDisciplineId = null;
    }


    close() {
        this.isSended = false;
        this.getData();
        this.showValidationErrors = false;
        this.resetForm();
    }

    resetForm(replace = false) {
        if (!replace) {
            this.form.reset();
        } else {
            this.formResetToggle = false;

            setTimeout(() => {
                this.formResetToggle = true;
            });
        }
    }

    private onFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        this.router.navigate(['/']);
    }

}
