export class TournamentManagement {

    id: number;
    tournamentId: number;
    permissionsId: number;
    userId: string;
    userName: string;
    fullName: string;

}
