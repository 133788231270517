

export class TournamentCompetition {

    id: number;
    tournamentId: number;
    entryFee: number;
    competitionId: number;
    competitionName: string;


    

}
