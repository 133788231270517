import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { Utilities } from './services/utilities';
import { RegisterComponent } from './components/register/register.component';
import { ResetpassComponent } from './components/resetpass/resetpass.component';
import { ApplicationComponent } from './components/zones/player/application/application.component';
import { HomeTournamentComponent } from './components/home/home-tournament/home-tournament.component';
import { PlayerTournamentsComponent } from './components/zones/player/tournaments/player-tournaments.component';
import { OrganizerTournamentsComponent } from './components/zones/organizer/tournaments/organizer-tournaments.component';
import { ManagerTournamentsComponent } from './components/zones/manager/tournaments/manager-tournaments.component';
import { JudgeTournamentsComponent } from './components/zones/judge/tournaments/judge-tournaments.component';
import { PlayerTournamentComponent } from './components/zones/player/tournament/player-tournament.component';
import { OrganizerTournamentComponent } from './components/zones/organizer/tournament/organizer-tournament.component';
import { ManagerTournamentComponent } from './components/zones/manager/tournament/tournament.component';
import { JudgeTournamentComponent } from './components/zones/judge/tournament/tournament.component';
import { AdminHomeComponent } from './components/zones/admin/home/home.component';


@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        const possibleSeparators = /[?;#]/;
        const indexOfSeparator = url.search(possibleSeparators);
        let processedUrl: string;

        if (indexOfSeparator > -1) {
            const separator = url.charAt(indexOfSeparator);
            const urlParts = Utilities.splitInTwo(url, separator);
            urlParts.firstPart = urlParts.firstPart.toLowerCase();

            processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
        } else {
            processedUrl = url.toLowerCase();
        }

        return super.parse(processedUrl);
    }
}


const routes: Routes = [
    { path: '', component: HomeComponent, data: { title: 'Home' } },
    { path: 'login', component: LoginComponent, data: { title: 'Login' } },
    { path: 'resetpass', component: ResetpassComponent, data: { title: 'Reset' } },
    { path: 'register', component: RegisterComponent, data: { title: 'Register' } },
    { path: 'tournament/:id', component: HomeTournamentComponent, data: { title: 'Tournament' } },
    { path: 'application/:id', component: ApplicationComponent, canActivate: [AuthGuard], data: { title: 'Application' } },

    { path: 'zones/player/home', component: PlayerTournamentsComponent, canActivate: [AuthGuard], data: { title: 'Player\'s Zone' } },
    { path: 'player/tournament/:id', component: PlayerTournamentComponent, canActivate: [AuthGuard], data: { title: 'Player\'s Zone' } },

    { path: 'zones/organizer/home', component: OrganizerTournamentsComponent, canActivate: [AuthGuard], data: { title: 'Organizer\'s Zone' } },
    { path: 'organizer/tournament/:id', component: OrganizerTournamentComponent, canActivate: [AuthGuard], data: { title: 'Organizer\'s Zone' } },

    { path: 'zones/manager/home', component: ManagerTournamentsComponent, canActivate: [AuthGuard], data: { title: 'Manager\'s Zone' } },
    { path: 'manager/tournament/:id', component: ManagerTournamentComponent, canActivate: [AuthGuard], data: { title: 'Manager\'s Zone' } },

    { path: 'zones/judge/home', component: JudgeTournamentsComponent, canActivate: [AuthGuard], data: { title: 'Judge\'s Zone' } },
    { path: 'judge/tournament/:id', component: JudgeTournamentComponent, canActivate: [AuthGuard], data: { title: 'Judge\'s Zone' } },

    { path: 'zones/admin/home', component: AdminHomeComponent, canActivate: [AuthGuard], data: { title: 'Admin\'s Zone' } },


    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { title: 'Settings' } },
    { path: 'about', component: AboutComponent, data: { title: 'About Us' } },
    { path: 'home', redirectTo: '/', pathMatch: 'full' },
    { path: '**', component: NotFoundComponent, data: { title: 'Page Not Found' } }
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        AuthService,
        AuthGuard,
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }]
})
export class AppRoutingModule { }
