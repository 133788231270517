
import { Component, ViewChild, Input,  } from '@angular/core';
import { Utilities } from '../../../../services/utilities';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { Discipline } from '../../../../models/discipline.model';
import { TournamentService } from '../../../../services/tournament.service';
import { Tournament } from '../../../../models/tournament.model';






@Component({
    selector: 'app-tournament-info',
    templateUrl: './tournament-info.html',
    styleUrls: ['./tournament-info.scss']
})
export class TournamentInfoComponent   {



    public isEditMode = false;
    public isSaving = false;
    public isEditingSelf = false;
    public showValidationErrors = false;
    public uniqueId: string = Utilities.uniqueId();

    @Input()
    tournament: Tournament;



    disciplines: Discipline[];


    public formResetToggle = true;

    public changesSavedCallback: (id: number) => void;
    public changesFailedCallback: () => void;
    public changesCancelledCallback: () => void;


    @ViewChild('f')
    public form;


    @ViewChild('tournamentName')
    public tournamentName;

    @ViewChild('dateRange')
    public dateRange;

    @ViewChild('description')
    public description;

    @ViewChild('disciplineId')
    public disciplineId;

    @ViewChild('place')
    public place;



    constructor(private alertService: AlertService, private tournamentService: TournamentService) {
    }

   
    edit() {
        this.tournamentService.getDisciplines().subscribe(results => this.disciplines = results, error => this.onDataLoadFailed(error));
        this.isEditMode = true;
        this.showValidationErrors = true;
    }


    save() {
        this.isSaving = true;
        this.alertService.startLoadingMessage('Saving changes...');
        this.tournament.dateStart = this.tournament.dateRange[0];
        this.tournament.dateEnd = this.tournament.dateRange[1];
        if (this.tournament.id > 0) {
            this.tournamentService.updateTournament(this.tournament).subscribe(results => this.saveSuccessHelper(results), error => this.saveFailedHelper(error));
        }
        else {
        this.tournamentService.createTournament(this.tournament).subscribe(results => this.saveSuccessHelper(results), error => this.saveFailedHelper(error));
        }
    }


    private saveSuccessHelper(results: Tournament) {

        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.showValidationErrors = false;


        this.alertService.showMessage('Success', 'Changes saved successfully', MessageSeverity.success);

        this.isEditMode = false;
        if (this.changesSavedCallback) {
            this.changesSavedCallback(results.id);
        }
    }






    private saveFailedHelper(error: any) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);

        if (this.changesFailedCallback) {
            this.changesFailedCallback();
        }
    }





    cancel() {

        this.showValidationErrors = false;

        this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
        this.alertService.resetStickyMessage();

        this.isEditMode = false;

        if (this.changesCancelledCallback) {
            this.changesCancelledCallback();
        }
    }





    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`, MessageSeverity.error, error);
    }

    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }

    resetForm(replace = false) {

        if (!replace) {
            this.form.reset();
        } else {
            this.formResetToggle = false;

            setTimeout(() => {
                this.formResetToggle = true;
            });
        }
    }

}
