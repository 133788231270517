import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../../../services/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Tournament } from '../../../../models/tournament.model';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { TournamentService } from '../../../../services/tournament.service';
import { Utilities } from '../../../../services/utilities';

@Component({
    selector: 'app-judge-tournament',
    templateUrl: './tournament.component.html',
    styleUrls: ['./tournament.component.scss'],
    animations: [fadeInOut]
})
export class JudgeTournamentComponent implements OnInit {

    tournament: Tournament;

    constructor(private router: Router, private alertService: AlertService, private translationService: AppTranslationService, private tournamentService: TournamentService, private route: ActivatedRoute, private location: Location) { }


    ngOnInit(): void {
        this.loadData();
    }


    private loadData() {
        this.alertService.startLoadingMessage();
        const id = Number(this.route.snapshot.paramMap.get('id'));
        this.tournamentService.getTournamentById(id).subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));

    }

    private onDataLoadSuccessful(tournament: Tournament) {
        this.alertService.stopLoadingMessage();
        this.tournament = tournament;

    }


    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


    clickKibicuj() {
        this.router.navigate(['application']);
    }


    clickZawodnik() {
        this.router.navigate(['application']);
    }


    goBack(): void {
        this.location.back();
    }


}
