import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { fadeInOut } from '../../../../services/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Tournament } from '../../../../models/tournament.model';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { TournamentService } from '../../../../services/tournament.service';
import { Utilities } from '../../../../services/utilities';
import { TournamentCompetition } from '../../../../models/tournament-competitions.model';
import { Competition } from '../../../../models/Competition.model';
import { BootstrapTabDirective } from '../../../../directives/bootstrap-tab.directive';
import { TournamentCompetitionsComponent } from '../../../controls/tournament/tournament-competitions/tournament-competitions';

@Component({
    selector: 'app-organizer-tournament',
    templateUrl: './organizer-tournament.component.html',
    styleUrls: ['./organizer-tournament.component.scss'],
    animations: [fadeInOut]
})
export class OrganizerTournamentComponent implements OnInit {


    isHomeActivated = true;
    isCompetitionsActivated = false;
    isPlayersActivated = false;
    isJudgesActivated = false;
    isManagersActivated = false;
    isPhasesActivated = false;


    readonly homeTab = 'home';
    readonly competitionsTab = 'competitions';
    readonly playersTab = 'players';
    readonly judgesTab = 'judges';
    readonly managersTab = 'managers';
    readonly phasesTab = 'phases';


    @ViewChild('tab', { static: true })
    tab: BootstrapTabDirective;


    @ViewChild('competitionsEditor')
    competitionsEditor: TournamentCompetitionsComponent;

    tournament: Tournament = new Tournament();
    closeResult: string;

  

    formResetToggle = true;



    @ViewChild('f')
    public form;


    @ViewChild('entryFee')
    public entryFee;


    @ViewChild('daneTemplate', { static: true })
    daneTemplate: TemplateRef<any>;

    @ViewChild('actionTemplate', { static: true })
    actionTemplate: TemplateRef<any>;





    constructor(private router: Router, private alertService: AlertService, private translationService: AppTranslationService, private tournamentService: TournamentService, private route: ActivatedRoute, private location: Location) { }


    ngOnInit(): void {
        const gT = (key: string) => this.translationService.getTranslation(key);
        this.loadData();
    }








    private loadData() {
        this.alertService.startLoadingMessage();
        const id = Number(this.route.snapshot.paramMap.get('id'));
        this.tournamentService.getTournamentById(id).subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));

    }

    private onDataLoadSuccessful(tournament: Tournament) {
        this.alertService.stopLoadingMessage();
        this.tournament = tournament;
        this.tournament.dateRange = [tournament.dateStart, tournament.dateEnd];
    }

    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }


    onShowTab(event) {
        const activeTab = event.target.hash.split('#', 2).pop();

        this.isHomeActivated = activeTab === this.homeTab;
        this.isCompetitionsActivated = activeTab === this.competitionsTab;
        this.isPlayersActivated = activeTab === this.playersTab;
        this.isJudgesActivated = activeTab === this.judgesTab;
        this.isManagersActivated = activeTab === this.managersTab;
        this.isPhasesActivated = activeTab === this.phasesTab;

        this.router.navigate([], { fragment: activeTab });



    }

}
