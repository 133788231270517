<div>
    <form *ngIf="formResetToggle" autocomplete="off" name="tournamentInfoForm" #f="ngForm" novalidate
          (ngSubmit)="f.form.valid ? save() :
           (!tournamentName.valid && showErrorAlert('Tournament name is required', 'Please enter a tournament name.'));">




        <div class="row">
            <div class="col-lg-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>

        <div class="form-group row">
            <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label" for="tournamentName-{{uniqueId}}">{{'tournament.Name' | translate}}</label>
            <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <p class="form-control-plaintext">{{tournament.tournamentName}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <input type="text" attr.id="tournamentName-{{uniqueId}}" name="tournamentName" placeholder="Enter tournament name" class="form-control" [ngClass]="{'is-valid': f.submitted && tournamentName.valid, 'is-invalid' : f.submitted && !tournamentName.valid}" [(ngModel)]="tournament.tournamentName" #tournamentName="ngModel" required  />
                <span *ngIf="showValidationErrors && f.submitted && tournamentName.errors?.required" class="invalid-feedback">
                    {{'tournament.TournamentNameRequired' | translate}}
                </span>
            </div>
        </div>
      

        <div class="row">
            <div class="col-lg-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>



        <div class="form-group row">
            <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label" for="dateRange-{{uniqueId}}">{{'tournament.Termin' | translate}}</label>
            <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <p class="form-control-plaintext">{{tournament.dateStart | date:'dd-MM-yyyy'}} - {{tournament.dateEnd | date:'dd-MM-yyyy'}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <input type="text" bsDaterangepicker attr.id="dateRange-{{uniqueId}}" name="dateRange" placeholder="Enter date" class="form-control" [ngClass]="{'is-valid': f.submitted && dateRange.valid, 'is-invalid' : f.submitted && !dateRange.valid}" [(ngModel)]="tournament.dateRange" #dateRange="ngModel" required [bsConfig]="{ isAnimated: true, rangeInputFormat :'YYYY-MM-DD' ,dateInputFormat: 'DD-MM-YYYY' }" />
                <span *ngIf="showValidationErrors && f.submitted && dateRange.errors?.required" class="invalid-feedback">
                    {{'tournament.TerminRequired' | translate}}
                </span>
            </div>
        </div>





        <div class="row">
            <div class="col-lg-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>



        <div class="form-group row  description-form-group">
            <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label" for="description-{{uniqueId}}">{{'tournament.Description' | translate}}</label>
            <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <p class="form-control-plaintext">{{tournament.description}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <textarea class="form-control" attr.id="description-{{uniqueId}}" name="description" placeholder="Enter description" [(ngModel)]="tournament.description" rows="4"></textarea>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>

        <div class="form-group row">
            <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label" for="disciplineId-{{uniqueId}}">{{'tournament.Discipline' | translate}}</label>
            <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <p class="form-control-plaintext">{{tournament.disciplineName}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <select class="form-control" attr.id="disciplineId-{{uniqueId}}" name="disciplineId" placeholder="Select discipline" required [(ngModel)]="tournament.disciplineId" #disciplineId="ngModel" [ngClass]="{'is-valid': f.submitted && disciplineId.valid, 'is-invalid' : f.submitted && !disciplineId.valid}">
                    <option *ngFor="let discipline of disciplines" [value]="discipline.id">{{discipline.disciplineName}}</option>
                </select>
                <span *ngIf="showValidationErrors && f.submitted && disciplineId.errors?.required" class="invalid-feedback">
                    {{'tournament.DisciplineRequired' | translate}}
                </span>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>







        <div class="form-group row">
            <label [class.col-lg-3]="isViewOnly" [class.col-lg-2]="!isViewOnly" class="col-form-label" for="place-{{uniqueId}}">{{'tournament.Place' | translate}}</label>
            <div *ngIf="!isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <p class="form-control-plaintext">{{tournament.place}}</p>
            </div>
            <div *ngIf="isEditMode" [class.col-lg-9]="isViewOnly" [class.col-lg-10]="!isViewOnly">
                <input type="text" attr.id="place-{{uniqueId}}" name="place" placeholder="Enter place" class="form-control" [ngClass]="{'is-valid': f.submitted && place.valid, 'is-invalid' : f.submitted && !place.valid}"
                       [(ngModel)]="tournament.place" #place="ngModel" required />
                <span *ngIf="showValidationErrors && f.submitted && place.errors?.required" class="invalid-feedback">
                    {{'tournament.PlaceRequired' | translate}}
                </span>
            </div>
        </div>



        <div class="row">
            <div class="col-lg-12">
                <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
            </div>
        </div>



        <div *ngIf="!isViewOnly" class="form-group row">
            <div class="col-sm-5">

            </div>
            <div class="col-sm-7">
                <div ngPreserveWhitespaces class="float-right">

                    <button *ngIf="!isEditMode" type="button" (click)="edit()" class="btn btn-outline-secondary"><i class='fa fa-edit'></i> {{'users.editor.Edit' | translate}}</button>

                    <button *ngIf="isEditMode" type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
                    <button *ngIf="isEditMode" type="submit" class="btn btn-primary" [disabled]="isSaving">
                        <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Save' | translate)}}
                    </button>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </form>
</div>
