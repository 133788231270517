<div class="d-flex h-90" [@fadeInOut]>
    <div class="login-container m-auto">
        <div class="card boxshadow">
            <div class="card-header bg-primary dark text-white clearfix">
                {{'application.Header' | translate}}
            </div>
            <div class="card-body">
                <div class="col-md-8 offset-md-2">
                    <form *ngIf="formResetToggle" name="userInfoForm" #f="ngForm" novalidate autocomplete="off"
                          (ngSubmit)="f.form.valid ? send() :
                           (playerName && playerName.errors?.required && showErrorAlert('Full name is required', 'Please enter full name.'));
                          (clubName && clubName.errors?.required && showErrorAlert('Club name is required', 'Please enter club name.'));
                           (tournamentCompetitionIds && tournamentCompetitionIds.errors?.required && showErrorAlert('Competitions is required', 'Please select competitions.'));
                          ">
                        

                        <div>
                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="playerName">{{'application.PlayerName' | translate}}</label>
                                <div class="col-md-9">
                                    <input type="text" [typeahead]="userPlayers" attr.id="playerName" name="playerName" placeholder="Enter full name" class="form-control" required [(ngModel)]="application.playerName" #playerName="ngModel" (typeaheadOnSelect)="playerSelected($event)" typeaheadOptionField="playerName"  (typeaheadNoResults)="playerNoResults($event)"/>
                                    <span *ngIf="showValidationErrors && f.submitted && playerName.errors?.required" class="invalid-feedback">
                                        {{'application.PlayerNameRequired' | translate}}
                                    </span>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>


                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="dateOfBirth">{{'application.DateOfBirth' | translate}}</label>
                                <div class="col-md-9">
                                    <input type="text" bsDatepicker attr.id="dateOfBirth" name="dateOfBirth" placeholder="Enter date of birth" class="form-control" [(ngModel)]="application.dateOfBirth" #dateOfBirth="ngModel" [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY-MM-DD' }" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="club">{{'application.Club' | translate}}</label>
                                <div class="col-md-9">
                                    <input type="text" [typeahead]="disciplineClubs" attr.id="clubName" name="clubName" placeholder="Enter club" class="form-control" required [(ngModel)]="application.clubName" #clubName="ngModel" (typeaheadOnSelect)="clubSelected($event)" typeaheadOptionField="clubName"  (typeaheadNoResults)="clubNoResults($event)"/>
                                    <span *ngIf="showValidationErrors && f.submitted && clubName.errors?.required" class="invalid-feedback">
                                        {{'application.ClubRequired' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="tournamentCompetitionId">{{'application.Competition' | translate}}</label>
                                <div class="col-md-9">
                                    <select class="form-control" attr.id="tournamentCompetitionIds" name="tournamentCompetitionIds" placeholder="Select competition" required [(ngModel)]="application.tournamentCompetitionIds" #tournamentCompetitionIds="ngModel" multiple>
                                        <option *ngFor="let competition of competitions" [value]="competition.id">{{competition.competitionName}}</option>
                                    </select>
                                    <span *ngIf="showValidationErrors && f.submitted && tournamentCompetitionIds.errors?.required" class="invalid-feedback">
                                        {{'application.CompetitionRequired' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>


                            <div class="form-group row">
                                <div class="col-sm-5">

                                </div>
                                <div class="col-sm-7">
                                    <div ngPreserveWhitespaces class="float-right">
                                        <button type="button" (click)="close()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i> {{'application.Cancel' | translate}}</button>
                                        <button type="submit" class="btn btn-primary" [disabled]="isSaving">
                                            {{isSending ? ('application.Sending' | translate) : ('application.Send' | translate)}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
