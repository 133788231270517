<div class="container">

    <header class="pageHeader">
        <h3><i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.PlayerZone' | translate}}</h3>
    </header>

    <div [@fadeInOut]>

        <div class="row control-box">
            <div class="col-lg-12">
                <div class="form-group search-box">
                    <app-search-box (searchChange)="onSearchChanged($event)" placeholder="{{'tournament.Search' | translate}}"></app-search-box>
                </div>
            </div>


        </div>




        <ngx-datatable class="material colored-header sm table-hover"
                       [loadingIndicator]="loadingIndicator"
                       [rows]="rows"
                       [columnMode]="'force'"
                       [columns]="columns"
                       [headerHeight]="35"
                       [footerHeight]="35"
                       rowHeight="auto"
                       [limit]="5"
                       [selected]="selected"
                       [selectionType]="'single'"
                       (activate)="onActivate($event)"
                       (select)="onSelect($event)">
        </ngx-datatable>


        <ng-template #nameTemplate let-row="row" let-value="value">
            <span class="inline-label">
                {{value}}
            </span>
        </ng-template>

        <ng-template #dateTemplate let-row="row" let-value="value">
            <span class="inline-label">
                {{value | date:'dd-MM-yyyy'}}
            </span>
        </ng-template>
    </div>

</div>