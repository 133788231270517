
<div class="row control-box">
    <div class="col-lg-8">
    </div>
    <div class="col-lg-4">
        <ul class="nav flex-column flex-lg-row float-right">
            <li class="nav-item toolbaritem">
                <a class="nav-link" href="javascript:;" (click)="addManagement()"><i class="fa fa-plus"></i> {{'management.AddManagement' | translate}}</a>
            </li>
        </ul>
    </div>
</div>
<ngx-datatable class="material colored-header sm table-hover"
               [columnMode]="'force'"
               [columns]="managementColumns"
               [headerHeight]="35"
               [footerHeight]="35"
               rowHeight="auto"
               [rows]="managementRows"
               [count]="managementTotalCount"
               [limit]="20">
</ngx-datatable>


<ng-template #daneTemplate let-row="row" let-value="value">
    <span class="inline-label">
        {{value}}
    </span>
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
    <div class="text-center">
        <a (click)="deleteManagement(value)" href="javascript:;"><i class="fa fa-trash"></i></a>
    </div>
</ng-template>

<div class="modal fade" bsModal #managementEditorModal="bs-modal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title float-left"><i class="fa fa-tasks"></i> {{'management.AddManagement' | translate}}</h4>
                <button type="button" class="close" title="Close" (click)="managementEditorModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form *ngIf="formResetToggle" name="managementEditorForm" #f="ngForm" novalidate autocomplete="off" (ngSubmit)="f.form.valid ? saveManagement() :   (email.errors?.required && showErrorAlert('Email is required', 'Please enter an email address (maximum of 200 characters)'));
          (email.errors?.pattern && showErrorAlert('Invalid Email', 'Please enter a valid email address')); ">
                    <div class="form-group row">
                        <label class="col-form-label col-md-3" for="email">{{'management.Email' | translate}}</label>
                        <div class="col-md-9">
                            <input type="text" attr.id="email" name="email" placeholder="Enter email address" class="form-control" [ngClass]="{'is-valid': f.submitted && email.valid, 'is-invalid' : f.submitted && !email.valid}"
                                   [(ngModel)]="newManagement.userName" #email="ngModel" required maxlength="200" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" />
                            <span *ngIf="showValidationErrors && f.submitted && email.errors?.required" class="invalid-feedback">
                                {{'management.EmailRequired' | translate}}
                            </span>
                            <span *ngIf="showValidationErrors && f.submitted && email.errors?.pattern" class="invalid-feedback">
                                {{'management.InvalidEmail' | translate}}
                            </span>
                        </div>
                    </div>
                    <div class="form-group actionBtn-form-group">
                        <div class="float-right">
                            <button type="submit" class="btn btn-primary">{{'management.AddManagement' | translate}}</button>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
    </div>
</div>

