<div class="d-flex h-90" [@fadeInOut]>
  <div class="login-container m-auto">
      <div class="card boxshadow">
          <div class="card-header bg-primary dark text-white clearfix">
              <i class="fa fa-lock" aria-hidden="true"></i>  {{'mainMenu.ResetPass' | translate}}
          </div>
          <div class="card-body">
              <div class="col-md-8 offset-md-2">
                  <form *ngIf="formResetToggle" name="userInfoForm" #f="ngForm" novalidate [attr.autocomplete]="'NaN'"
                        (ngSubmit)="isSended ? save() : f.form.valid ? send() :
                        (email && email.errors?.required && showErrorAlert('Email is required', 'Please enter an email address (maximum of 200 characters)'));
                        (email && email.errors?.pattern && showErrorAlert('Invalid Email', 'Please enter a valid email address'));">



                      <div *ngIf="!isSended">
                        


                          <div class="form-group row">
                              <label class="col-form-label col-md-3" for="email">{{'users.editor.Email' | translate}}</label>
                              <div class="col-md-9">
                                  <input type="text" attr.id="email" name="email" placeholder="Enter email address" class="form-control" [ngClass]="{'is-valid': f.submitted && email.valid, 'is-invalid' : f.submitted && !email.valid}"
                                         [(ngModel)]="userEdit.email" #email="ngModel" required maxlength="200" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" />
                                  <span *ngIf="showValidationErrors && f.submitted && email.errors?.required" class="invalid-feedback">
                                      {{'users.editor.EmailRequired' | translate}}
                                  </span>
                                  <span *ngIf="showValidationErrors && f.submitted && email.errors?.pattern" class="invalid-feedback">
                                      {{'users.editor.InvalidEmail' | translate}}
                                  </span>
                              </div>
                          </div>


                        

                         





                         




                          <div class="form-group row">
                              <div class="col-sm-5">

                              </div>
                              <div class="col-sm-7">
                                  <div ngPreserveWhitespaces class="float-right">
                                      <button type="submit" class="btn btn-primary" [disabled]="isSaving">
                                          <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Next' | translate)}}
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>










                      <div *ngIf="isSended">
                          <div class="form-group row">
                              <label class="col-form-label col-md-3" for="codeEmail">{{'users.editor.CodeEmail' | translate}}</label>
                              <div class="col-md-9">
                                  <input type="text" attr.id="codeEmail" name="codeEmail" placeholder="Enter Email code" class="form-control" [ngClass]="{'is-valid': f.submitted && codeEmail.valid, 'is-invalid' : f.submitted && !codeEmail.valid}"
                                         [(ngModel)]="userEdit.codeEmail" #codeEmail="ngModel" required />
                                  <span *ngIf="showValidationErrors && f.submitted && codeEmail.errors?.required" class="invalid-feedback">
                                      {{'users.editor.CodeEmailRequired' | translate}}
                                  </span>
                              </div>
                          </div>

                          <div class="row">
                              <div class="col-lg-12">
                                  <hr class="edit-separator-hr" />
                              </div>
                          </div>


                          <div class="form-group row">
                              <label class="col-form-label col-md-3" for="codeSms">{{'users.editor.CodeSms' | translate}}</label>
                              <div class="col-md-9">
                                  <input type="text" attr.id="codeSms" name="codeSms" placeholder="Enter SMS code" class="form-control" [ngClass]="{'is-valid': f.submitted && codeSms.valid, 'is-invalid' : f.submitted && !codeSms.valid}"
                                         [(ngModel)]="userEdit.codeSms" #codeSms="ngModel" required />
                                  <span *ngIf="showValidationErrors && f.submitted && codeSms.errors?.required" class="invalid-feedback">
                                      {{'users.editor.CodeSmsRequired' | translate}}
                                  </span>
                              </div>
                          </div>


                          <div class="row">
                              <div class="col-lg-12">
                                  <hr class="edit-separator-hr" />
                              </div>
                          </div>

                          <div class="form-group row">
                              <div class="col-sm-5">

                              </div>
                              <div class="col-sm-7">
                                  <div ngPreserveWhitespaces class="float-right">
                                      <button type="button" (click)="close()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
                                      <button type="submit" class="btn btn-primary" [disabled]="isSaving">
                                          <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Next' | translate)}}
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="clearfix"></div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>
