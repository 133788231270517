import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';


import { TournamentEndpoint } from './endpoints/tournament-endpoint.service';
import { Tournament } from '../models/tournament.model';
import { Player } from '../models/player.model';
import { Club } from '../models/club.model';
import { ClubDiscipline } from '../models/club-discipline.model';
import { Discipline } from '../models/discipline.model';
import { Competition } from '../models/Competition.model';
import { TournamentCompetition } from '../models/tournament-competitions.model';
import { TournamentManagement } from '../models/tournament-management.model';
import { NewTournamentApplication } from '../models/new-tournament-application.model';
import { TournamentApplication } from '../models/tournament-application.model';
import { TournamentPhase } from '../models/tournament-phase.model';


@Injectable()
export class TournamentService {

    public data: any;

    constructor(private tournamentEndpoint: TournamentEndpoint) {
    }

    getPlayerTournaments(): Observable<Tournament[]> {
        return this.tournamentEndpoint.getPlayerTournamentsEndpoint<Tournament[]>();
    }
    getOrganizerTournaments(): Observable<Tournament[]> {
        return this.tournamentEndpoint.getOrganizerTournamentsEndpoint<Tournament[]>();
    }

    getManagerTournaments(): Observable<Tournament[]> {
        return this.tournamentEndpoint.getManagerTournamentsEndpoint<Tournament[]>();
    }
    getJudgeTournaments(): Observable<Tournament[]> {
        return this.tournamentEndpoint.getJudgeTournamentsEndpoint<Tournament[]>();
    }

    getAllTournaments(): Observable<Tournament[]> {
        return this.tournamentEndpoint.getAllTournamentsEndpoint<Tournament[]>();
    }

    getTournamentById(id : number): Observable<Tournament> {
        return this.tournamentEndpoint.getTournamentByIdEndpoint<Tournament>(id);
    }

    createApplication(application: NewTournamentApplication): Observable<NewTournamentApplication> {
        return this.tournamentEndpoint.createApplicationEndpoint<NewTournamentApplication>(application);
    }

    createTournament(tournament: Tournament): Observable<Tournament> {
        return this.tournamentEndpoint.createTournamentEndpoint<Tournament>(tournament);
    }
    updateTournament(tournament: Tournament): Observable<Tournament> {
        return this.tournamentEndpoint.updateTournamentEndpoint<Tournament>(tournament);
    }


    createTournamentCompetition(tournamentCompetition: TournamentCompetition): Observable<TournamentCompetition> {
        return this.tournamentEndpoint.createTournamentCompetitionEndpoint<TournamentCompetition>(tournamentCompetition);
    }

    getUserPlayers(): Observable<Player[]>  {
        return this.tournamentEndpoint.getUserPlayersEndpoint<Player[]>();
    }

    getDisciplineClubs(discipline: number): Observable<ClubDiscipline[]> {
        return this.tournamentEndpoint.getDisciplineClubsEndpoint<ClubDiscipline[]>(discipline);
    }

    getDisciplines(): Observable<Discipline[]> {
        return this.tournamentEndpoint.getDisciplinesEndpoint<Discipline[]>();
    }

    getDisciplineCompetitions(tournament: number): Observable<Competition[]> {
        return this.tournamentEndpoint.getDisciplineCompetitionsEndpoint<Competition[]>(tournament);
    }

    deleteTournamentCompetitionById(id: number): Observable<boolean> {
        return this.tournamentEndpoint.deleteTournamentCompetitionByIdEndpoint<boolean>(id);
    }

    getTournamentCompetitions(id: number): Observable<TournamentCompetition[]> {
        return this.tournamentEndpoint.getTournamentCompetitionsEndpoint<TournamentCompetition[]>(id);
    }


    getTournamentManagements(id: number): Observable<TournamentManagement[]> {
        return this.tournamentEndpoint.getTournamentManagementsEndpoint<TournamentManagement[]>(id);
    }

    deleteTournamentManagementById(id: number): Observable<boolean> {
        return this.tournamentEndpoint.deleteTournamentManagementByIdEndpoint<boolean>(id);
    }

    createTournamentManagement(tournamentManagement: TournamentManagement): Observable<TournamentManagement> {
        return this.tournamentEndpoint.createTournamentManagementEndpoint<TournamentManagement>(tournamentManagement);
    }

    getTournamentJudges(id: number): Observable<TournamentManagement[]> {
        return this.tournamentEndpoint.getTournamentJudgesEndpoint<TournamentManagement[]>(id);
    }

    deleteTournamentJudgeById(id: number): Observable<boolean> {
        return this.tournamentEndpoint.deleteTournamentJudgeByIdEndpoint<boolean>(id);
    }

    createTournamentJudge(tournamentJudge: TournamentManagement): Observable<TournamentManagement> {
        return this.tournamentEndpoint.createTournamentJudgeEndpoint<TournamentManagement>(tournamentJudge);
    }

    getTournamentApplications(id: number): Observable<TournamentApplication[]> {
        return this.tournamentEndpoint.getTournamentApplicationsEndpoint<TournamentApplication[]>(id);
    }

    deleteTournamentApplicationById(id: number): Observable<boolean> {
        return this.tournamentEndpoint.deleteTournamentApplicationByIdEndpoint<boolean>(id);
    }



    getTournamentPhases(id: number): Observable<TournamentPhase[]> {
        return this.tournamentEndpoint.getTournamentPhasesEndpoint<TournamentPhase[]>(id);
    }

    deleteTournamentPhaseById(id: number): Observable<boolean> {
        return this.tournamentEndpoint.deleteTournamentPhaseByIdEndpoint<boolean>(id);
    }

    createTournamentPhase(tournamentPhase: TournamentPhase): Observable<TournamentPhase> {
        return this.tournamentEndpoint.createTournamentPhaseEndpoint<TournamentPhase>(tournamentPhase);
    }




}
