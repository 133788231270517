import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class DisciplineEndpoint extends EndpointBase {

    get disciplinesUrl() { return this.configurations.baseUrl + '/api/admin/discipline/all'; }
    get disciplineUrl() { return this.configurations.baseUrl + '/api/admin/discipline'; }

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService);
    }



    getAllDisciplinesEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.disciplinesUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getAllDisciplinesEndpoint());
            }))
    }



    deleteDisciplineByIdEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.disciplineUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.deleteDisciplineByIdEndpoint(id));
            }));
    }


    createDisciplineEndpoint<T>(discipline: any): Observable<T> {
        return this.http.post<T>(this.disciplineUrl, JSON.stringify(discipline), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.createDisciplineEndpoint(discipline));
            }));
    }


    editDisciplineEndpoint<T>(discipline: any): Observable<T> {

        return this.http.put<T>(this.disciplineUrl, JSON.stringify(discipline), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.createDisciplineEndpoint(discipline));
            }));
    }

}
