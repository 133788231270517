<div class="app-component app-container">
    <ngx-toasta></ngx-toasta>

    <nav id="header" class="app-component navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
        <div class="container">
            <a ngPreserveWhitespaces class="app-component navbar-brand" routerLink="/">
                <!--<img src="assets/images/logo-white.png" class="d-inline-block align-top" alt="logo">-->
                <span class="app-component appTitle">{{appTitle}}</span>
            </a>
            <button type="button" class="app-component navbar-toggler" data-toggle="collapse" data-target=".menuItemsContainer.app-component">
                <span class="navbar-toggler-icon"></span>
            </button>





            <div class="app-component collapse navbar-collapse menuItemsContainer">

                <ul class="app-component nav nav-pills flex-column flex-lg-row mr-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false">{{'mainMenu.Tournaments' | translate}}</a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="/zones/player/home">{{'mainMenu.PlayerZone' | translate}}</a>
                            <a class="dropdown-item" href="/zones/organizer/home">{{'mainMenu.OrganizerZone' | translate}}</a>
                            <a class="dropdown-item" href="/zones/manager/home">{{'mainMenu.ManagerZone' | translate}}</a>
                            <a class="dropdown-item" href="/zones/judge/home">{{'mainMenu.JudgeZone' | translate}}</a>
                            <block *ngIf="isUserLoggedIn">
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="/zones/admin/home">{{'mainMenu.AdminZone' | translate}}</a>
                            </block>
                        </div>
                    </li>
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/about">{{'mainMenu.About' | translate}}</a>
                    </li>
                </ul>






                <ul *ngIf="!isUserLoggedIn" class="app-component nav nav-pills flex-column flex-lg-row ml-auto">
                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/login">{{'mainMenu.Login' | translate}}</a>
                    </li>
                </ul>



                <ul *ngIf="isUserLoggedIn" class="app-component nav nav-pills flex-column flex-lg-row mr-md-2">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="fa fa-cog"></i></a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="/settings">{{'mainMenu.Settings' | translate}}</a>
                            <a class="dropdown-item" (click)="logout()" href="javascript:;"><i class="fa fa-sign-out"></i> {{'mainMenu.Logout' | translate}}</a>
                        </div>

                    <li class="nav-item" routerLinkActive="active">
                        <a class="nav-link" routerLink="/settings"></a>
                    </li>
                </ul>


                    <!--<span class="app-component navbar-text notifications-popup">{{'app.Welcome' | translate}}</span>
    <a class="app-component nav-link user-name d-inline-block px-1" [popover]="popTemplate" (onHidden)="markNotificationsAsRead()" placement="bottom" [popoverTitle]="notificationsTitle" href="javascript:;" triggers="focus">
        {{userName}}
        <span *ngIf="newNotificationCount > 0" class="badge badge-pill badge-secondary">{{newNotificationCount}}</span>
    </a>
    <ng-template #popTemplate>
        <app-notifications-viewer [isViewOnly]="true"></app-notifications-viewer>
    </ng-template>
    <span class="app-component navbar-text">, </span>-->
                  
                        
             



            </div>





        </div>
    </nav>

    <div id="pre-bootstrap" *ngIf="!removePrebootScreen" [class.prebootShow.app-component]="!isAppLoaded" class="app-component prebootStep">
        <div class="messaging">
            <h1>
                Loaded!
            </h1>
            <p>
                Universal<span style="font-style:italic">Game</span>Counter &copy; <a href="http://www.prostafirma.pl">www.prostafirma.pl</a>
            </p>

        </div>
    </div>

    <main class="app-component container">
        <router-outlet></router-outlet>
        <div class="app-component footer-height"></div>
    </main>

    <footer class="app-component footer fixed-bottom">
        <div class="container">
            <p class="text-center text-muted">
                <span style="font-style:italic">universal</span>GameCounter &copy; {{getYear()}}
                <span class="small display-none">- Powered by <a href="https://www.prostafirma.pl" target="_blank"><span style="font-style:italic">prostafirma.pl</span></a></span>
            </p>
        </div>
    </footer>

    <div *ngIf="shouldShowLoginModal" class="modal fade login-control" bsModal #loginModal="bs-modal" (onShown)="onLoginModalShown()" (onHidden)="onLoginModalHidden()" (onHide)="onLoginModalHide()"
         [config]="{backdrop: 'static'}" tabindex="-1">
        <div class="modal-dialog modal-lg h-75 d-flex flex-column justify-content-center my-0">
            <div class="modal-content">
                <div class="modal-body">
                    <app-login #loginControl isModal="true"></app-login>
                </div>
            </div>
        </div>
    </div>
</div>
