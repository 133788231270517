export class TournamentApplication {

    id: number;
    tournamentCompetitionId: number;
    playerId: number;
    clubDisciplineId: number;
    playerName: string;
    clubName: string;
    dateOfBirth?: Date;
    competitionName: string;

}


