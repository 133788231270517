import { Component } from '@angular/core';



@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html'
})
export class BannerComponent {

}
