import { User } from './user.model';


export class UserEdit extends User {
    constructor(currentPassword?: string, newPassword?: string, confirmPassword?: string, codeEmail?: string, codeSms?: string) {
        super();

        this.currentPassword = currentPassword;
        this.newPassword = newPassword;
        this.confirmPassword = confirmPassword;
        this.codeEmail = codeEmail;
        this.codeSms = codeSms;
    }

    public currentPassword: string;
    public newPassword: string;
    public confirmPassword: string;
    public codeEmail: string;
    public codeSms: string;

}
