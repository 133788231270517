<div class="container">
    <div [@fadeInOut] >
        <div class="row">

            <div class="col-12 my-3">
                <app-banner></app-banner>
            </div>

            <div class="col-12 my-3">
                <app-home-tournaments></app-home-tournaments>
            </div>

        </div>
    </div>
</div>
