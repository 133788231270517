<div class="container">


    <header class="pageHeader">
        <h3>{{'pageHeader.TournamentCard'  | translate}}</h3>
    </header>



    <div [@fadeInOut]>
        <div class="d-sm-flex flex-row">
            <ul appBootstrapTab #tab="bootstrap-tab" (showBSTab)="onShowTab($event)" class="nav nav-tabs nav-tabs--vertical nav-tabs--left" role="navigation">
                <li class="nav-item">
                    <a id="homeTab" [routerLink]="[]" fragment="home" data-target="#home" href="#home" class="nav-link active" data-toggle="tab" role="tab" aria-controls="home">
                        <i class="fa fa-user-circle-o fa-fw" aria-hidden="true"></i> {{'tournament.card.Home' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a id="competitionsTab" [routerLink]="[]" fragment="competitions" data-target="#competitions" href="#competitions" class="nav-link" data-toggle="tab" role="tab" aria-controls="competitions">
                        <i class="fa fa-sliders fa-fw" aria-hidden="true"></i> {{'tournament.card.Competitions' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a id="managersTab" [routerLink]="[]" fragment="managers" data-target="#managers" href="#managers" class="nav-link" data-toggle="tab" role="tab" aria-controls="managers">
                        <i class="fa fa-shield fa-fw" aria-hidden="true"></i> {{'tournament.card.Managers'  | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a id="judgesTab" [routerLink]="[]" fragment="judges" data-target="#judges" href="#judges" class="nav-link" data-toggle="tab" role="tab" aria-controls="judges">
                        <i class="fa fa-shield fa-fw" aria-hidden="true"></i> {{'tournament.card.Judges' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a id="playersTab" [routerLink]="[]" fragment="players" data-target="#players" href="#players" class="nav-link" data-toggle="tab" role="tab" aria-controls="players">
                        <i class="fa fa-users fa-fw" aria-hidden="true"></i> {{'tournament.card.Players' | translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a id="phasesTab" [routerLink]="[]" fragment="phases" data-target="#phases" href="#phases" class="nav-link" data-toggle="tab" role="tab" aria-controls="phases">
                        <i class="fa fa-sliders fa-fw" aria-hidden="true"></i> {{'tournament.card.Phases' | translate}}
                    </a>
                </li>
            </ul>



            <div class="tab-content w-100">
                <div class="tab-pane show active" id="home" role="tabpanel">
                    <h4>{{'tournament.card.HomeHeader' | translate}}</h4>
                    <hr class="separator-hr" />
                    <div [@fadeInOut] *ngIf="isHomeActivated" class="content-container pl-lg-2">
                        <app-tournament-info [tournament]="tournament"></app-tournament-info>
                    </div>
                </div>
                <div class="tab-pane" id="competitions" role="tabpanel">
                    <h4>{{'tournament.card.CompetitionsHeader' | translate}}</h4>
                    <hr class="separator-hr" />
                    <div [@fadeInOut] *ngIf="isCompetitionsActivated" class="content-container pl-lg-2">
                        <app-tournament-competitions [tournamentId]="tournament.id"></app-tournament-competitions>
                    </div>
                </div>
                <div class="tab-pane" id="players" role="tabpanel">
                    <h4>{{'tournament.card.PlayersHeader' | translate}}</h4>
                    <hr class="separator-hr" />
                    <div [@fadeInOut] *ngIf="isPlayersActivated" class="content-container pl-lg-1">
                        <app-tournament-players [tournamentId]="tournament.id"></app-tournament-players>
                    </div>
                </div>
                <div class="tab-pane" id="judges" role="tabpanel">
                    <h4>{{'tournament.card.JudgesHeader' | translate}}</h4>
                    <hr class="separator-hr" />
                    <div [@fadeInOut] *ngIf="isJudgesActivated" class="content-container pl-lg-1">
                        <app-tournament-judges [tournamentId]="tournament.id"></app-tournament-judges>
                    </div>
                </div>
                <div class="tab-pane" id="managers" role="tabpanel">
                    <h4>{{'tournament.card.ManagersHeader' | translate}}</h4>
                    <hr class="separator-hr" />
                    <div [@fadeInOut] *ngIf="isManagersActivated" class="content-container pl-lg-1">
                        <app-tournament-managements [tournamentId]="tournament.id"></app-tournament-managements>
                    </div>
                </div>
                <div class="tab-pane" id="phases" role="tabpanel">
                    <h4>{{'tournament.card.PhasesHeader' | translate}}</h4>
                    <hr class="separator-hr" />
                    <div [@fadeInOut] *ngIf="isPhasesActivated" class="content-container pl-lg-1">
                        <app-tournament-phases [tournamentId]="tournament.id"></app-tournament-phases>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
