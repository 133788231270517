import { ClubDiscipline } from './club-discipline.model';

export class Club {

    id: number;
    clubName: string;

    clubDisciplines: ClubDiscipline[];

    disciplines: number[];

}
