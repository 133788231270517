import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { Discipline } from '../models/discipline.model';
import { DisciplineEndpoint } from './endpoints/discipline-endpoint.service';


@Injectable()
export class DisciplineService {

    public data: any;

    constructor(private disciplineEndpoint: DisciplineEndpoint) {
    }

    getAllDisciplines(): Observable<Discipline[]> {
        return this.disciplineEndpoint.getAllDisciplinesEndpoint<Discipline[]>();
    }

    deleteDisciplineById(id: number): Observable<boolean> {
        return this.disciplineEndpoint.deleteDisciplineByIdEndpoint<boolean>(id);
    }


    createDiscipline(discipline: Discipline): Observable<Discipline> {
        return this.disciplineEndpoint.createDisciplineEndpoint<Discipline>(discipline);
    }

    editDiscipline(discipline: Discipline): Observable<Discipline> {
        return this.disciplineEndpoint.editDisciplineEndpoint<Discipline>(discipline);
    }

}
