import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Club } from '../models/club.model';
import { ClubEndpoint } from './endpoints/club-endpoint.service';



@Injectable()
export class ClubService {

    public data: any;

    constructor(private clubEndpoint: ClubEndpoint) {
    }

    getAllClubs(): Observable<Club[]> {
        return this.clubEndpoint.getAllClubsEndpoint<Club[]>();
    }

    deleteClubById(id: number): Observable<boolean> {
        return this.clubEndpoint.deleteClubByIdEndpoint<boolean>(id);
    }


    createClub(club: Club): Observable<Club> {
        return this.clubEndpoint.createClubEndpoint<Club>(club);
    }

    editClub(club: Club): Observable<Club> {
        return this.clubEndpoint.editClubEndpoint<Club>(club);
    }

}
