
import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { User } from '../../models/user.model';
import { UserEdit } from '../../models/user-edit.model';
import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { Router } from '@angular/router';
import { fadeInOut } from '../../services/animations';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    animations: [fadeInOut]
})

export class RegisterComponent implements OnInit {


    public isSaving = false;
    public isSended = false;
    public showValidationErrors = false;
    public user: User = new User();
    public userEdit: UserEdit;
    public allRoles: Role[] = [];

    public formResetToggle = true;






    @ViewChild('f')
    public form;

    @ViewChild('userPassword')
    public userPassword;

    @ViewChild('email')
    public email;

    @ViewChild('currentPassword')
    public currentPassword;

    @ViewChild('newPassword')
    public newPassword;

    @ViewChild('confirmPassword')
    public confirmPassword;


    constructor(private router: Router, private alertService: AlertService, private accountService: AccountService) {
    }

    ngOnInit() {

        this.newUser();
    }






    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }


    deletePasswordFromUser(user: UserEdit | User) {
        const userEdit = user as UserEdit;

        delete userEdit.currentPassword;
        delete userEdit.newPassword;
        delete userEdit.confirmPassword;
    }


    newUser() {
        this.user = this.userEdit = new UserEdit();
        this.userEdit.isEnabled = true;
        this.userEdit = new UserEdit();
        Object.assign(this.userEdit, this.user);
        this.showValidationErrors = true;

    }

    send() {
        this.isSaving = true;
        this.alertService.startLoadingMessage('Saving changes...');

        this.userEdit.userName = this.userEdit.email;
        this.userEdit.roles = [];

        this.accountService.getAuthenticationCodes(this.userEdit).subscribe(a => this.sendSuccessHelper(), error => this.sendFailedHelper(error));
    }
    private sendSuccessHelper() {
        this.isSaving = false;
        this.isSended = true;
        this.alertService.stopLoadingMessage();
        this.showValidationErrors = false;
        this.alertService.showMessage('Success', `User \"${this.user.userName}\" was created successfully`, MessageSeverity.success);


    }
    private sendFailedHelper(error: any) {
        this.isSaving = false;
        this.isSended = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    }









    save() {
        this.isSaving = true;
        this.alertService.startLoadingMessage('Saving changes...');

        this.userEdit.userName = this.userEdit.email;
        this.userEdit.roles = [];

        this.accountService.registerUser(this.userEdit).subscribe(user => this.saveSuccessHelper(user), error => this.saveFailedHelper(error));

    }
    private saveSuccessHelper(user?: User) {
        this.testIsRoleUserCountChanged(this.user, this.userEdit);

        if (user) {
            Object.assign(this.userEdit, user);
        }

        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.showValidationErrors = false;

        this.deletePasswordFromUser(this.userEdit);
        Object.assign(this.user, this.userEdit);
        this.userEdit = new UserEdit();
        this.resetForm();


        this.alertService.showMessage('Success', `User \"${this.user.userName}\" was created successfully`, MessageSeverity.success);

        this.router.navigate(['/']);


    }
    private saveFailedHelper(error: any) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);


    }














    private testIsRoleUserCountChanged(currentUser: User, editedUser: User) {

        const rolesAdded = editedUser.roles;
        const rolesRemoved = [];

        const modifiedRoles = rolesAdded.concat(rolesRemoved);

        if (modifiedRoles.length) {
            setTimeout(() => this.accountService.onRolesUserCountChanged(modifiedRoles));
        }
    }


    close() {
        this.isSended = false;
        this.userEdit = this.user = new UserEdit();
        this.showValidationErrors = false;
        this.resetForm();

    }




    resetForm(replace = false) {

        if (!replace) {
            this.form.reset();
        } else {
            this.formResetToggle = false;

            setTimeout(() => {
                this.formResetToggle = true;
            });
        }
    }


}
