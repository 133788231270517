<div class="container">

    <header class="pageHeader">
        <h3><i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.OrganizerZone' | translate}}</h3>
    </header>

    <div [@fadeInOut]>

        <div class="row control-box">
            <div class="col-lg-8">
                <div class="form-group search-box">
                    <app-search-box (searchChange)="onSearchChanged($event)" placeholder="{{'tournament.Search' | translate}}"></app-search-box>
                </div>
            </div>
            <div class="col-lg-4">
                <ul class="nav flex-column flex-lg-row float-right">
                    <li class="nav-item toolbaritem">
                        <a class="nav-link" href="javascript:;" (click)="addTournament()"><i class="fa fa-plus"></i> {{'tournament.AddTournament' | translate}}</a>
                    </li>
                </ul>
            </div>
        </div>




        <ngx-datatable class="material colored-header sm table-hover"
                       [loadingIndicator]="loadingIndicator"
                       [rows]="rows"
                       [columnMode]="'force'"
                       [columns]="columns"
                       [headerHeight]="35"
                       [footerHeight]="35"
                       rowHeight="auto"
                       [limit]="5"
                       [selected]="selected"
                       [selectionType]="'single'"
                       (activate)="onActivate($event)"
                       (select)="onSelect($event)">
        </ngx-datatable>








        <ng-template #nameTemplate let-row="row" let-value="value">
            <span class="inline-label">
                {{value}}
            </span>
        </ng-template>

        <ng-template #dateTemplate let-row="row" let-value="value">
            <span class="inline-label">
                {{value | date:'dd-MM-yyyy'}}
            </span>
        </ng-template>


    </div>


    <div class="modal fade" bsModal #editorModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title float-left"><i class="fa fa-tasks"></i> {{'tournament.NewTournament' | translate}}</h4>
                    <button type="button" class="close" title="Close" (click)="editorModal.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-tournament-info #tournamentEditor [tournament]="newTournament"></app-tournament-info>
                </div>
            </div>
        </div>
    </div>
</div>