import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ToastaModule } from 'ngx-toasta';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ChartsModule } from 'ng2-charts';




import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';
import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { ThemeManager } from './services/theme-manager';
import { LocalStoreManager } from './services/local-store-manager.service';
import { OidcHelperService } from './services/oidc-helper.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/endpoints/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/endpoints/account-endpoint.service';
import { TournamentEndpoint } from './services/endpoints/tournament-endpoint.service';


import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
import { BootstrapToggleDirective } from './directives/bootstrap-toggle.directive';
import { GroupByPipe } from './pipes/group-by.pipe';

import { AppComponent } from './components/app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RegisterComponent } from './components/register/register.component';

import { BannerComponent } from './components/controls/banner/banner.component';
import { TodoComponent } from './components/controls/todo/todo.component';
import { StatisticsComponent } from './components/controls/statistics/statistics.component';
import { NotificationsViewerComponent } from './components/controls/notifications-viewer/notifications-viewer.component';
import { SearchBoxComponent } from './components/controls/search-box/search-box.component';
import { UserInfoComponent } from './components/controls/user-info/user-info.component';
import { UserPreferencesComponent } from './components/controls/user-preferences/user-preferences.component';
import { UsersManagementComponent } from './components/controls/users-management/users-management.component';
import { RolesManagementComponent } from './components/controls/roles-management/roles-management.component';
import { RoleEditorComponent } from './components/controls/role-editor/role-editor.component';
import { ResetpassComponent } from './components/resetpass/resetpass.component';
import { HomeTournamentsComponent } from './components/home/home-tournaments/home-tournaments.component';
import { TournamentService } from './services/tournament.service';
import { HomeTournamentComponent } from './components/home/home-tournament/home-tournament.component';
import { ApplicationComponent } from './components/zones/player/application/application.component';
import { PlayerTournamentsComponent } from './components/zones/player/tournaments/player-tournaments.component';
import { PlayerTournamentComponent } from './components/zones/player/tournament/player-tournament.component';
import { OrganizerTournamentsComponent } from './components/zones/organizer/tournaments/organizer-tournaments.component';
import { JudgeTournamentsComponent } from './components/zones/judge/tournaments/judge-tournaments.component';
import { ManagerTournamentsComponent } from './components/zones/manager/tournaments/manager-tournaments.component';
import { OrganizerTournamentComponent } from './components/zones/organizer/tournament/organizer-tournament.component';
import { ManagerTournamentComponent } from './components/zones/manager/tournament/tournament.component';
import { JudgeTournamentComponent } from './components/zones/judge/tournament/tournament.component';
import { AdminHomeComponent } from './components/zones/admin/home/home.component';
import { TournamentInfoComponent } from './components/controls/tournament/tournament-info/tournament-info';
import { TournamentCompetitionsComponent } from './components/controls/tournament/tournament-competitions/tournament-competitions';
import { DisciplineService } from './services/discipline.service';
import { DisciplineEndpoint } from './services/endpoints/discipline-endpoint.service';
import { DisciplinesManagmentComponent } from './components/controls/disciplines/disciplines-management/disciplines-management.component';
import { ClubsManagementComponent } from './components/controls/clubs/clubs-managment/clubs-management.component';
import { ClubEndpoint } from './services/endpoints/club-endpoint.service';
import { ClubService } from './services/club.service';
import { TournamentManagementsComponent } from './components/controls/tournament/tournament-managements/tournament-managements';
import { TournamentJudgesComponent } from './components/controls/tournament/tournament-judges/tournament-judges';
import { TournamentPlayersComponent } from './components/controls/tournament/tournament-players/tournament-players';
import { TournamentPhasesComponent } from './components/controls/tournament/tournament-phases/tournament-phases';





@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        NgxDatatableModule,
        BsDatepickerModule.forRoot(),
        OAuthModule.forRoot(),
        ToastaModule.forRoot(),
        NgSelectModule,
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        ChartsModule
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        ResetpassComponent,
        RegisterComponent,
        HomeComponent,
        HomeTournamentComponent,
        PlayerTournamentsComponent, PlayerTournamentComponent,
        OrganizerTournamentsComponent, OrganizerTournamentComponent,
        ManagerTournamentsComponent, ManagerTournamentComponent,
        JudgeTournamentsComponent, JudgeTournamentComponent,
        SettingsComponent,
        UsersManagementComponent, UserInfoComponent, UserPreferencesComponent,
        TournamentInfoComponent, TournamentCompetitionsComponent, TournamentManagementsComponent, TournamentJudgesComponent, TournamentPlayersComponent, TournamentPhasesComponent,
        RolesManagementComponent, RoleEditorComponent,
        DisciplinesManagmentComponent, ClubsManagementComponent,
        AdminHomeComponent,
        AboutComponent,
        NotFoundComponent,
        NotificationsViewerComponent,
        ApplicationComponent,
        SearchBoxComponent,
        StatisticsComponent, TodoComponent, BannerComponent,
        HomeTournamentsComponent,
        EqualValidator,
        LastElementDirective,
        AutofocusDirective,
        BootstrapTabDirective,
        BootstrapToggleDirective,
        GroupByPipe
    ],
    providers: [
        { provide: ErrorHandler, useClass: AppErrorHandler },
        AlertService,
        ThemeManager,
        ConfigurationService,
        TournamentService,
        TournamentEndpoint,
        DisciplineService,
        DisciplineEndpoint,
        ClubEndpoint,
        ClubService,
        AppTitleService,
        AppTranslationService,
        NotificationService,
        NotificationEndpoint,
        AccountService,
        AccountEndpoint,
   
        LocalStoreManager,
        OidcHelperService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
