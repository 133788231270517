export class NewTournamentApplication {

    id: number;
    tournamentCompetitionIds: number[];
    playerId: number;
    clubDisciplineId: number;
    disciplineId: number;
    userId: string;
    playerName: string;
    clubName: string;
    dateOfBirth?: Date;


}


