<div class="container settings-page">
  <header class="pageHeader">
      <h3><i class="fa fa-cog fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.AdminZone' | translate}}</h3>
  </header>

  <div [@fadeInOut]>
    <div class="d-sm-flex flex-row">
        <ul appBootstrapTab #tab="bootstrap-tab" (showBSTab)="onShowTab($event)" class="nav nav-tabs nav-tabs--vertical nav-tabs--left" role="navigation">

            <li class="nav-item">
                <a id="usersTab" [routerLink]="[]" fragment="users" data-target="#users" href="#users" class="nav-link" data-toggle="tab" role="tab" aria-controls="users">
                    <i class="fa fa-users fa-fw" aria-hidden="true"></i> {{'settings.tab.Users' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a id="rolesTab" [routerLink]="[]" fragment="roles" data-target="#roles" href="#roles" class="nav-link" data-toggle="tab" role="tab" aria-controls="roles">
                    <i class="fa fa-shield fa-fw" aria-hidden="true"></i> {{'settings.tab.Roles' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a id="disciplinesTab" [routerLink]="[]" fragment="disciplines" data-target="#disciplines" href="#disciplines" class="nav-link" data-toggle="tab" role="tab" aria-controls="disciplines">
                    <i class="fa fa-shield fa-fw" aria-hidden="true"></i> {{'settings.tab.Disciplines' | translate}}
                </a>
            </li>
            <li class="nav-item">
                <a id="clubsTab" [routerLink]="[]" fragment="clubs" data-target="#clubs" href="#clubs" class="nav-link" data-toggle="tab" role="tab" aria-controls="clubs">
                    <i class="fa fa-shield fa-fw" aria-hidden="true"></i> {{'settings.tab.Clubs' | translate}}
                </a>
            </li>

        </ul>
        <div class="tab-content w-100">
            <div class="tab-pane show active" id="users" role="tabpanel">
                <h4>{{'settings.header.UsersManagements' | translate}}</h4>
                <hr class="separator-hr" />
                <div [@fadeInOut]  class="content-container pl-lg-1">
                    <app-users-management></app-users-management>
                </div>
            </div>
            <div class="tab-pane" id="roles" role="tabpanel">
                <h4>{{'settings.header.RolesManagements' | translate}}</h4>
                <hr class="separator-hr" />
                <div [@fadeInOut]  class="content-container pl-lg-1">
                    <app-roles-management></app-roles-management>
                </div>
            </div>
            <div class="tab-pane" id="disciplines" role="tabpanel">
                <h4>{{'settings.header.DisciplinesManagements' | translate}}</h4>
                <hr class="separator-hr" />
                <div [@fadeInOut] class="content-container pl-lg-1">
                    <app-disciplines-management></app-disciplines-management>
                </div>
            </div>
            <div class="tab-pane" id="clubs" role="tabpanel">
                <h4>{{'settings.header.ClubsManagements' | translate}}</h4>
                <hr class="separator-hr" />
                <div [@fadeInOut] class="content-container pl-lg-1">
                    <app-clubs-management></app-clubs-management>
                </div>
            </div>

        </div>
    </div>
  </div>

</div>
