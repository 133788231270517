import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../../services/auth.service';
import { AlertService, MessageSeverity, DialogType } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { LocalStoreManager } from '../../../../services/local-store-manager.service';
import { Utilities } from '../../../../services/utilities';
import { TournamentService } from '../../../../services/tournament.service';
import { Tournament } from '../../../../models/tournament.model';
import { fadeInOut } from '../../../../services/animations';
import { Router } from '@angular/router';
import { Discipline } from '../../../../models/discipline.model';
import { TournamentInfoComponent } from '../../../controls/tournament/tournament-info/tournament-info';




@Component({
    selector: 'app-organizer-tournaments',
    templateUrl: './organizer-tournaments.component.html',
    styleUrls: ['./organizer-tournaments.component.scss'],
    animations: [fadeInOut]
})
export class OrganizerTournamentsComponent implements OnInit {


    rows = [];
    rowsCache = [];
    columns = [];
    selected = [];

    isDataLoaded = false;
    loadingIndicator = true;
    _currentUserId: string;
    _hideCompletedTasks = false;

    newTournament: Tournament = new Tournament();

    get currentUserId() {
        if (this.authService.currentUser) {
            this._currentUserId = this.authService.currentUser.id;
        }
        return this._currentUserId;
    }


    @Input()
    verticalScrollbar = false;



    @ViewChild('nameTemplate', { static: true })
    nameTemplate: TemplateRef<any>;

    @ViewChild('dateTemplate', { static: true })
    dateTemplate: TemplateRef<any>;

    @ViewChild('editorModal', { static: true })
    editorModal: ModalDirective;



    @ViewChild('tournamentEditor', { static: true })
    tournamentEditor: TournamentInfoComponent;




    @ViewChild('tournamentName')
    public tournamentName;

    @ViewChild('dateRange')
    public dateRange;

    @ViewChild('disciplineId')
    public disciplineId;





    constructor(private router: Router, private alertService: AlertService, private translationService: AppTranslationService, private localStorage: LocalStoreManager, private authService: AuthService, private tournamentService: TournamentService) {
    }



    ngOnInit() {
        this.loadingIndicator = true;
        this.loadData();


        const gT = (key: string) => this.translationService.getTranslation(key);

        this.columns = [
            { prop: 'tournamentName', name: gT('tournament.Name'), cellTemplate: this.nameTemplate },
            { prop: 'dateStart', name: gT('tournament.DateStart'), cellTemplate: this.dateTemplate, width: 120, canAutoResize: false },
            { prop: 'dateEnd', name: gT('tournament.DateEnd'), cellTemplate: this.dateTemplate, width: 120, canAutoResize: false }
        ];

    }


    ngAfterViewInit() {

        this.tournamentEditor.changesSavedCallback = (id) => {
            this.router.navigate(['organizer/tournament/' + id]);
            this.editorModal.hide();
        };

        this.tournamentEditor.changesCancelledCallback = () => {
            this.editorModal.hide();
        };
    }




    private loadData() {
        this.alertService.startLoadingMessage();
        this.tournamentService.getOrganizerTournaments().subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));
    }

    private onDataLoadSuccessful(competition: Tournament[]) {
        this.alertService.stopLoadingMessage();
        this.rows = competition;
        this.rowsCache = [...competition];
        this.loadingIndicator = false;

    }

    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        this.loadingIndicator = false;
    }


    onSearchChanged(value: string) {
        this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.tournamentName, r.place));
    }

    onSelect({ selected }) {
        this.router.navigate(['organizer/tournament/' + selected[0].id]);
    }

    onActivate(event) {
    }



    addTournament() {
        this.tournamentEditor.edit();
        this.editorModal.show();
    }




    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }


}
