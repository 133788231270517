<div>
  <div class="form-group row">
    <label for="refreshButton" class="col-sm-3 col-form-label">{{'preferences.ReloadPreferences' | translate}} </label>
    <div class="col-sm-4">
      <button id="refreshButton" class="btn btn-outline-secondary" (click)="reloadFromServer()">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
    <div class="col-sm-5">
      <p class="form-control-plaintext text-muted small">{{'preferences.ReloadPreferencesHint' | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="separator-hr" />
    </div>
  </div>

  <div class="form-group row">
    <label for="language" class="col-sm-3 col-form-label">{{'preferences.Language' | translate}} </label>
    <div class="col-sm-4">
      <ng-select id="language" [(ngModel)]="configurations.language" [searchable]="false" [clearable]="false" class="form-control">
        <ng-option [value]="'en'">{{'preferences.English' | translate}} <small class="text-muted"><i>({{'preferences.Default' | translate}})</i></small></ng-option>
        <ng-option [value]="'fr'">{{'preferences.French' | translate}}</ng-option>
        <ng-option [value]="'de'">{{'preferences.German' | translate}}</ng-option>
        <ng-option [value]="'pt'">{{'preferences.Portuguese' | translate}}</ng-option>
        <ng-option [value]="'ar'">{{'preferences.Arabic' | translate}}</ng-option>
        <ng-option [value]="'ko'">{{'preferences.Korean' | translate}}</ng-option>
      </ng-select>
    </div>
    <div class="col-sm-5">
      <p class="form-control-plaintext text-muted small">{{'preferences.LanguageHint' | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="separator-hr" />
    </div>
  </div>

  <div class="form-group row">
    <label for="homePage" class="col-sm-3 col-form-label">{{'preferences.HomePage' | translate}} </label>
    <div class="col-sm-4">
      <ng-select id="homePage" [(ngModel)]="configurations.homeUrl" [searchable]="false" [clearable]="false" class="form-control">
        <ng-option [value]="'/'"><i class=" fa fa-tachometer"></i> {{'preferences.Dashboard' | translate}} <small class="text-muted"><i>({{'preferences.Default' | translate}})</i></small></ng-option>
        <ng-option [value]="'/customers'" *ngIf="canViewCustomers"><i class=" fa fa-handshake-o"></i> {{'preferences.Customers' | translate}}</ng-option>
        <ng-option [value]="'/products'" *ngIf="canViewProducts"><i class=" fa fa-truck"></i> {{'preferences.Products' | translate}}</ng-option>
        <ng-option [value]="'/orders'" *ngIf="canViewOrders"><i class=" fa fa-shopping-cart"></i> {{'preferences.Orders' | translate}}</ng-option>
        <ng-option [value]="'/about'"><i class=" fa fa-info-circle"></i> {{'preferences.About' | translate}}</ng-option>
        <ng-option [value]="'/settings'"><i class=" fa fa-cog"></i> {{'preferences.Settings' | translate}}</ng-option>
      </ng-select>
    </div>
    <div class="col-sm-5">
      <p class="form-control-plaintext text-muted small">{{'preferences.HomePageHint' | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="separator-hr" />
    </div>
  </div>

  <div class="form-group row">
    <label for="defaultTheme" class="col-sm-3 col-form-label">{{'preferences.Theme' | translate}} </label>
    <div class="col-sm-4">
      <ng-select id="defaultTheme" [(ngModel)]="configurations.themeId" [searchable]="false" [clearable]="false" class="form-control theme-picker">
        <ng-option *ngFor="let theme of themeManager.themes" [value]="theme.id">
          <div class="theme-item" style="background-color: {{theme.background}}; color: {{theme.color}}">
            {{'preferences.' + theme.name + 'Theme' | translate}} <span class="small">{{theme.isDark ? '(Dark)' : ''}}</span>
          </div>
        </ng-option>
      </ng-select>
    </div>
    <div class="col-sm-5">
      <p class="form-control-plaintext text-muted small">{{'preferences.ThemeHint' | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="separator-hr" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label" for="dashboardStatistics">{{'preferences.DashboardStatistics' | translate}} </label>
    <div class="col-sm-4">
      <div class="checkbox">
        <input name="dashboardStatistics" [(ngModel)]="configurations.showDashboardStatistics" class="form-control" type="checkbox" appBootstrapToggle data-size="small">
      </div>
    </div>
    <div class="col-sm-5">
      <p class="form-control-plaintext text-muted small">{{'preferences.DashboardStatisticsHint' | translate}}</p>
    </div>
  </div>
  <!--  -->

  <div class="row">
    <div class="col-md-12">
      <hr class="subseparator-hr" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label" for="dashboardTodo">{{'preferences.DashboardNotifications' | translate}} </label>
    <div class="col-sm-4">
      <div class="checkbox">
        <input name="dashboardNotifications" [(ngModel)]="configurations.showDashboardNotifications" class="form-control" type="checkbox" appBootstrapToggle data-size="small">
      </div>
    </div>
    <div class="col-sm-5">
      <p class="form-control-plaintext text-muted small">{{'preferences.DashboardNotificationsHint' | translate}}</p>
    </div>
  </div>
  <!--  -->

  <div class="row">
    <div class="col-md-12">
      <hr class="subseparator-hr" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label" for="dashboardTodo">{{'preferences.DashboardTodo' | translate}} </label>
    <div class="col-sm-4">
      <div class="checkbox">
        <input name="dashboardTodo" [(ngModel)]="configurations.showDashboardTodo" class="form-control" type="checkbox" appBootstrapToggle data-size="small">
      </div>
    </div>
    <div class="col-sm-5">
      <p class="form-control-plaintext text-muted small">{{'preferences.DashboardTodoHint' | translate}}</p>
    </div>
  </div>
  <!--  -->


  <div class="row">
    <div class="col-md-12">
      <hr class="subseparator-hr" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label" for="dashboardBanner">{{'preferences.DashboardBanner' | translate}}</label>
    <div class="col-sm-4">
      <div class="checkbox">
        <input name="dashboardBanner" [(ngModel)]="configurations.showDashboardBanner" class="form-control" type="checkbox" appBootstrapToggle data-size="small">
      </div>
    </div>
    <div class="col-sm-5">
      <p class="form-control-plaintext text-muted small">{{'preferences.DashboardBannerHint' | translate}}</p>
    </div>
  </div>
  <!--  -->



  <div class="row">
    <div class="col-md-12">
      <hr class="last-separator-hr" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3">

    </div>
    <div class="col-sm-9">
      <div class="row float-left">
        <div class="col-sm-6 col-reset-default">
          <button type="button" (click)="resetDefault()" class="btn btn-success">
            <i class='fa fa-circle-o-notch'></i> {{'preferences.ResetDefault' | translate}}
          </button>
        </div>
        <div class="col-sm-6 col-set-default">
          <button type="button" (click)="setAsDefault()" class="btn btn-primary">
            <i class='fa fa-hdd-o'></i> {{'preferences.SetDefault' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
