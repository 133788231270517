<div class="reduced-font">
  <div>
    <carousel interval="6000">
      <slide>
        <img src="assets/images/demo/banner1.png" alt="ASP.NET" class="img-fluid" />
        <div class="carousel-caption">
          <p>
            Learn how to build ASP.NET apps that can run anywhere
            <a class="btn btn-outline-info btn-sm" href="http://go.microsoft.com/fwlink/?LinkID=525028&clcid=0x409" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </slide>
      <slide>
        <img src="assets/images/demo/banner2.png" alt="Visual Studio" class="img-fluid" />
        <div class="carousel-caption">
          <p>
            One platform for building modern web, native mobile and native desktop applications
            <a class="btn btn-outline-primary btn-sm" href="http://angular.io" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </slide>
      <slide>
        <img src="assets/images/demo/banner3.png" alt="Package Management" class="img-fluid" />
        <div class="carousel-caption">
          <p>
            Bring in libraries from NuGet and npm, and bundle with angular/cli
            <a class="btn btn-outline-success btn-sm" href="http://go.microsoft.com/fwlink/?LinkID=525029&clcid=0x409" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </slide>
      <slide>
        <img src="assets/images/demo/banner4.png" alt="Eben Monney" class="img-fluid" />
        <div class="carousel-caption">
          <p>
            Follow me on social media for updates and tips on using this startup project
            <a class="btn btn-outline-secondary btn-sm" href="https://www.ebenmonney.com/about" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </slide>
    </carousel>
  </div>


</div>
