<div class="container">

    <div [@fadeInOut]>
        <img [src]="tournament.logo" [alt]="tournament.tournamentName">
        <h5 class="card-title">{{tournament.tournamentName}}</h5>
        <p class="card-text">{{tournament.disciplineName}}</p>


        <p class="card-text">{{tournament.description}}</p>
        <p class="card-text"><small class="text-muted">{{tournament.place +' ' + (tournament.dateStart  | date:'dd-MM-yyyy')}}</small></p>


        <button type="button" class="btn btn-success btn-lg btn-block" (click)="clickAplication()">{{'tournament.GetAplication' | translate}}</button>

    </div>
</div>
