<div>
    <div class="row control-box">
        <div class="col-lg-8">
        </div>
        <div class="col-lg-4">
            <ul class="nav flex-column flex-lg-row float-right">
                <li class="nav-item toolbaritem">
                    <a class="nav-link" href="javascript:;" (click)="addDiscipline()"><i class="fa fa-plus"></i> {{'discipline.AddDiscipline' | translate}}</a>
                </li>
            </ul>
        </div>
    </div>
    <ngx-datatable class="material colored-header sm table-hover"
                   [loadingIndicator]="loadingIndicator"
                   [columnMode]="'force'"
                   [columns]="disciplineColumns"
                   [headerHeight]="35"
                   [footerHeight]="35"
                   rowHeight="auto"
                   [rows]="disciplineRows"
                   [count]="disciplineTotalCount"
                   [limit]="20">
    </ngx-datatable>


    <ng-template #daneTemplate let-row="row" let-value="value">
        <span class="inline-label">
            {{value}}
        </span>
    </ng-template>


    <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
        <div>
            <a class="btn btn-link btn-sm" href="javascript:;" (click)="editDiscipline(value)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> {{'discipline.Edit' | translate}}</a>|
            <a class="btn btn-link btn-sm" href="javascript:;" (click)="deleteDiscipline(value)"><i class="fa fa-trash-o" aria-hidden="true"></i> {{'discipline.Delete' | translate}}</a>
        </div>
    </ng-template>

    <div class="modal fade" bsModal #disciplineEditorModal="bs-modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 *ngIf="!editingDiscipline" class="modal-title float-left"><i class="fa fa-tasks"></i> {{'discipline.AddDiscipline' | translate}}</h4>
                    <h4 *ngIf="editingDiscipline" class="modal-title float-left"><i class="fa fa-tasks"></i> {{'discipline.EditDiscipline' | translate}}</h4>

                    <button type="button" class="close" title="Close" (click)="disciplineEditorModal.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form *ngIf="formResetToggle" name="disciplineEditorForm" #f="ngForm" novalidate autocomplete="off" (ngSubmit)="f.form.valid ? saveDiscipline() :  (!disciplineName.valid && showErrorAlert('Discipline name is required', 'Please enter a discipline name.')); ">
                        <div class="form-group row">
                            <label class="col-form-label col-md-3" for="disciplineName">{{'discipline.DisciplineName' | translate}}:</label>
                            <div class="col-md-9">
                                <input type="text" id="disciplineName" name="disciplineName" placeholder="Enter discipline name" class="form-control" [ngClass]="{'is-valid': f.submitted && disciplineName.valid, 'is-invalid' : f.submitted && !disciplineName.valid}" [(ngModel)]="newDiscipline.disciplineName" #disciplineName="ngModel" required />
                                <span *ngIf="f.submitted && !disciplineName.valid" class="invalid-feedback">
                                    {{'discipline.DisciplineNameRequired' | translate}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group actionBtn-form-group">
                            <div class="float-right">
                                <button type="submit" class="btn btn-primary">{{'discipline.Save' | translate}}</button>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>