import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { fadeInOut } from '../../../../services/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Tournament } from '../../../../models/tournament.model';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { TournamentService } from '../../../../services/tournament.service';
import { Utilities } from '../../../../services/utilities';
import { TournamentCompetition } from '../../../../models/tournament-competitions.model';
import { Competition } from '../../../../models/Competition.model';

@Component({
    selector: 'app-tournament-competitions',
    templateUrl: './tournament-competitions.html',
    styleUrls: ['./tournament-competitions.scss'],
    animations: [fadeInOut]
})
export class TournamentCompetitionsComponent implements OnInit {


    @Input()
    tournamentId: number;

    competitionRows: TournamentCompetition[] = [];
    competitionTotalCount: Number = 0;
 
    competitions: Competition[] = null;
    newCompetition: TournamentCompetition = new TournamentCompetition();


    formResetToggle = true;


    @ViewChild('competitionEditorModal', { static: true })
    competitionEditorModal: ModalDirective;


    @ViewChild('f')
    public form;


    @ViewChild('competitionId')
    public competitionId;

    @ViewChild('entryFee')
    public entryFee;


    @ViewChild('daneTemplate', { static: true })
    daneTemplate: TemplateRef<any>;

    @ViewChild('actionTemplate', { static: true })
    actionTemplate: TemplateRef<any>;


    competitionColumns = [];


    constructor(private router: Router, private alertService: AlertService, private translationService: AppTranslationService, private tournamentService: TournamentService, private route: ActivatedRoute, private location: Location) { }


    ngOnInit(): void {

        const gT = (key: string) => this.translationService.getTranslation(key);

        this.competitionColumns = [
            { prop: 'competitionName', name: gT('competition.Competition'), cellTemplate: this.daneTemplate },
            { prop: 'entryFee', name: gT('competition.EntryFee'), cellTemplate: this.daneTemplate, width: 120, canAutoResize: false },
            { prop: 'id', name: gT('competition.Action'), cellTemplate: this.actionTemplate, width: 100, canAutoResize: false }
        ];

        this.loadData();
    }




    private loadData() {
        this.alertService.startLoadingMessage();
        this.tournamentService.getTournamentCompetitions(this.tournamentId).subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));
    }

   

    private onDataLoadSuccessful(tournamentCompetitions: TournamentCompetition[]) {
        this.alertService.stopLoadingMessage();
        this.competitionRows = tournamentCompetitions;
        this.competitionTotalCount = this.competitionRows.length;
    }


    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


    deleteCompetition(id: number) {
        this.alertService.startLoadingMessage();
        this.tournamentService.deleteTournamentCompetitionById(id).subscribe(results => {
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }



    addCompetition() {
        this.formResetToggle = false;
        this.tournamentService.getDisciplineCompetitions(this.tournamentId).subscribe(results => {
            this.competitions = results;
            this.alertService.stopLoadingMessage();
        },
            error => this.onDataLoadFailed(error));

        this.formResetToggle = true;
        this.competitionEditorModal.show();

    }

    saveCompetition() {
        this.alertService.startLoadingMessage('Saving changes...');
        this.newCompetition.tournamentId = this.tournamentId;
        this.tournamentService.createTournamentCompetition(this.newCompetition).subscribe(results => {
            this.resetForm();
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }

    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }



    resetForm(replace = false) {
        this.alertService.stopLoadingMessage();
        this.competitionEditorModal.hide();
        this.competitions = null;
        this.newCompetition = new TournamentCompetition();
        if (!replace) {
            this.form.reset();
        } else {
            this.formResetToggle = false;

            setTimeout(() => {
                this.formResetToggle = true;
            });
        }
    }






}
