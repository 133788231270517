
<div class="row control-box">
    <div class="col-lg-8">
    </div>
    <div class="col-lg-4">


    </div>
</div>
<ngx-datatable class="material colored-header sm table-hover"
               [columnMode]="'force'"
               [columns]="applicationColumns"
               [headerHeight]="35"
               [footerHeight]="35"
               rowHeight="auto"
               [rows]="applicationRows"
               [count]="applicationTotalCount"
               [limit]="20">
</ngx-datatable>


<ng-template #daneTemplate let-row="row" let-value="value">
    <span class="inline-label">
        {{value}}
    </span>
</ng-template>

<ng-template #dateTemplate let-row="row" let-value="value">
    <span class="inline-label">
        {{value | date:'dd-MM-yyyy'}}
    </span>
</ng-template>

<ng-template #actionTemplate let-row="row" let-value="value">
    <div class="text-center">
        <a (click)="deleteApplication(value)" href="javascript:;"><i class="fa fa-trash"></i></a>
    </div>
</ng-template>


