import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { fadeInOut } from '../../../../services/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { TournamentService } from '../../../../services/tournament.service';
import { Utilities } from '../../../../services/utilities';
import { TournamentCompetition } from '../../../../models/tournament-competitions.model';
import { TournamentPhase } from '../../../../models/tournament-phase.model';

@Component({
    selector: 'app-tournament-phases',
    templateUrl: './tournament-phases.html',
    styleUrls: ['./tournament-phases.scss'],
    animations: [fadeInOut]
})
export class TournamentPhasesComponent implements OnInit {


    @Input()
    tournamentId: number;

    phaseRows: TournamentPhase[] = [];
    phaseTotalCount: Number = 0;
 
    tournamentCompetition: TournamentCompetition[] = null;
    newPhase: TournamentPhase = new TournamentPhase();


    formResetToggle = true;


    @ViewChild('phaseEditorModal', { static: true })
     phaseEditorModal: ModalDirective;


    @ViewChild('f')
    public form;


    @ViewChild('entryFee')
    public entryFee;


    @ViewChild('daneTemplate', { static: true })
    daneTemplate: TemplateRef<any>;

    @ViewChild('actionTemplate', { static: true })
    actionTemplate: TemplateRef<any>;


    phaseColumns = [];


    constructor(private router: Router, private alertService: AlertService, private translationService: AppTranslationService, private tournamentService: TournamentService, private route: ActivatedRoute, private location: Location) { }


    ngOnInit(): void {

        const gT = (key: string) => this.translationService.getTranslation(key);

        this.phaseColumns = [
            { prop: 'fullName', name: gT('phase.Phase'), cellTemplate: this.daneTemplate },
            { prop: 'id', name: gT('competition.Action'), cellTemplate: this.actionTemplate, width: 100, canAutoResize: false }
        ];

        this.loadData();
    }




    private loadData() {
        this.alertService.startLoadingMessage();
        this.tournamentService.getTournamentPhases(this.tournamentId).subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));
    }

   

    private onDataLoadSuccessful(tournamentPhases: TournamentPhase[]) {
        this.alertService.stopLoadingMessage();
        this.phaseRows = tournamentPhases;
        this.phaseTotalCount = this.phaseRows.length;
    }


    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


    deletePhase(id: number) {
        this.alertService.startLoadingMessage();
        this.tournamentService.deleteTournamentPhaseById(id).subscribe(results => {
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }



    addPhase() {
        this.formResetToggle = false;
        this.tournamentService.getTournamentCompetitions(this.tournamentId).subscribe(results => {
            this.tournamentCompetition = results;
            this.alertService.stopLoadingMessage();
        },
            error => this.onDataLoadFailed(error));

        this.formResetToggle = true;
        this.phaseEditorModal.show();

    }

    savePhase() {
        this.alertService.startLoadingMessage('Saving changes...');
        this.newPhase.tournamentId = this.tournamentId;
        this.tournamentService.createTournamentPhase(this.newPhase).subscribe(results => {
            this.resetForm();
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }

    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }



    resetForm(replace = false) {
        this.alertService.stopLoadingMessage();
        this.phaseEditorModal.hide();
        this.tournamentCompetition = null;
        this.newPhase = new TournamentPhase();
        if (!replace) {
            this.form.reset();
        } else {
            this.formResetToggle = false;

            setTimeout(() => {
                this.formResetToggle = true;
            });
        }
    }






}
