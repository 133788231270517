<div class="container">

    <header class="pageHeader">
        <h3>Karta zawodów zawodnika </h3>
    </header>




    <div [@fadeInOut]>
        <img  [src]="tournament.logo" [alt]="tournament.tournamentName">
        <h5 class="card-title">{{tournament.tournamentName}}</h5>
        <p class="card-text">Opis imprezy</p>
        <p class="card-text"><small class="text-muted">{{tournament.place +' ' + tournament.dateStart}}</small></p>

    </div>
</div>
