import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AuthService } from '../../../services/auth.service';
import { AlertService, MessageSeverity, DialogType } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { LocalStoreManager } from '../../../services/local-store-manager.service';
import { Utilities } from '../../../services/utilities';
import { TournamentService } from '../../../services/tournament.service';
import { Tournament } from '../../../models/tournament.model';



@Component({
    selector: 'app-home-tournaments',
    templateUrl: './home-tournaments.component.html',
    styleUrls: ['./home-tournaments.component.scss']
})
export class HomeTournamentsComponent implements OnInit {

    rows = [];
    rowsCache = [];

    isDataLoaded = false;
    loadingIndicator = true;
    formResetToggle = true;
    _currentUserId: string;
    _hideCompletedTasks = false;


    get currentUserId() {
        if (this.authService.currentUser) {
            this._currentUserId = this.authService.currentUser.id;
        }
        return this._currentUserId;
    }


    @Input()
    verticalScrollbar = false;




    constructor(private alertService: AlertService, private translationService: AppTranslationService, private localStorage: LocalStoreManager, private authService: AuthService, private tournamentService: TournamentService) {
    }

    ngOnInit() {
        this.loadingIndicator = true;
        this.loadData();
    }

    clickRow(value) {


    }

    private loadData() {
        this.alertService.startLoadingMessage();

        this.tournamentService.getAllTournaments().subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));

    }

    private onDataLoadSuccessful(competition: Tournament[]) {
        this.alertService.stopLoadingMessage();
        this.rows = competition;
        this.rowsCache = [...competition];

    }

    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }

    onSearchChanged(value: string) {
        this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.tournamentName, r.place));
    }


}
