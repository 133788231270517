<div class="container about-page">
    <header class="pageHeader">
        <h3><i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.About' | translate}}</h3>
    </header>

    <div class="reduced-font" [@fadeInOut]>
        <h4>Welcome to QuickApp</h4>
        <p>This application template provides a quick and convenient way to create ASP.NET Core 5.0 / Angular 11 applications</p>

    	<h6>NOTE: To hire developers for your project please go to <a href="https://www.ebenmonney.com/devlink" target="_blank">DevLink</a> or send a mail to <a target="_blank" href="mailto:devlink@ebenmonney.com">devlink@ebenmonney.com</a> for more info. </h6>

        <h5>Technologies</h5>
        <ul>
            <li>Template pages with <a href="https://angular.io" target="_blank" rel="nofollow">Angular 11</a> and <a href="https://www.typescriptlang.org" target="_blank" rel="nofollow">TypeScript</a></li>
            <li>RESTful API Backend using <a href="https://docs.microsoft.com/en-us/aspnet/core" target="_blank" rel="nofollow">ASP.NET Core 5.0</a> MVC Web API</li>
            <li>Database using <a href="https://docs.microsoft.com/en-us/ef/core" target="_blank" rel="nofollow">Entity Framework Core</a></li>
            <li>Authentication based on <a href="http://docs.identityserver.io/en/release" target="_blank" rel="nofollow">IdentityServer4</a> and <a href="https://docs.microsoft.com/en-us/aspnet/core/security/authentication/identity-configuration?tabs=aspnetcore2x" target="_blank" rel="nofollow">ASP.NET Core Identity</a></li>
            <li>API Documentation using <a href="https://swagger.io" target="_blank" rel="nofollow">Swagger</a></li>
            <li>Client-side built on <a href="https://cli.angular.io" target="_blank" rel="nofollow">Angular CLI</a></li>
            <li>Theming using <a href="https://getbootstrap.com" target="_blank" rel="nofollow">Bootstrap 4</a> with <a href="https://bootswatch.com" target="_blank" rel="nofollow">Bootswatch</a></li>
        </ul>

        <div style="position: relative" class="bg-light bg-features">
            <h5>Features</h5>
            <ul>
                <li>A complete backend and frontend project structure to build on, with login, user and permission-based role management integrated</li>
                <li>Clean and simple Angular/ASP.NET Core code to serve as a guide</li>
                <li>Data Access Layer built with the Repository and Unit of Work Pattern</li>
                <li>Code First Database</li>
                <li>A RESTful API Design</li>
                <li>Dialog and Notification Services</li>
                <li>Configuration Page and Configuration Service</li>
                <li>Integrated Internationaliztion</li>
                <li>Theming with SASS</li>
                <li>Ready-to-use email API</li>
                <li>Handling Access and Refresh Tokens with WebStorage (Bearer authentication) - No Cookies</li>
                <li>CRUD APIs</li>
                <li>etc.</li>
            </ul>
        </div>


    </div>
</div>
