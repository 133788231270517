<div class="container">

    <header class="pageHeader">
        <h3><i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> {{'pageHeader.ManagerZone' | translate}}</h3>
    </header>

    <div [@fadeInOut]>

        <div class="row control-box">
            <div class="col-lg-8">
                <div class="form-group search-box">
                    <app-search-box (searchChange)="onSearchChanged($event)" placeholder="{{'Tournament.Search' | translate}}"></app-search-box>
                </div>
            </div>
            <div class="col-lg-4">
                <ul class="nav flex-column flex-lg-row float-right">
                    <li class="nav-item toolbaritem">
                        <a class="nav-link" href="javascript:;" (click)="addTournament()"><i class="fa fa-plus"></i> {{'Tournament.AddTournament' | translate}}</a>
                    </li>
                </ul>
            </div>
        </div>




        <ngx-datatable class="material colored-header sm table-hover"
                       [loadingIndicator]="loadingIndicator"
                       [rows]="rows"
                       [columnMode]="'force'"
                       [columns]="columns"
                       [headerHeight]="35"
                       [footerHeight]="35"
                       rowHeight="auto"
                       [limit]="5"
                       [selected]="selected"
                       [selectionType]="'single'"
                       (activate)="onActivate($event)"
                       (select)="onSelect($event)">
        </ngx-datatable>







        <ng-template #nameTemplate let-row="row" let-value="value">
            <span class="inline-label">
                {{value}}
            </span>
        </ng-template>

        <ng-template #dateTemplate let-row="row" let-value="value">
            <span class="inline-label">
                {{value | date:'dd-MM-yyyy'}}
            </span>
        </ng-template>



        <div class="modal fade" bsModal #editorModal="bs-modal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title float-left"><i class="fa fa-tasks"></i> {{'todoDemo.editor.NewTask' | translate}}</h4>
                        <button type="button" class="close" title="Close" (click)="editorModal.hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form *ngIf="formResetToggle" name="taskEditorForm" #f="ngForm" novalidate
                              (ngSubmit)="f.form.valid ? save() :
                      (!taskName.valid && showErrorAlert('Task name is required', 'Please enter a name for the task'));">


                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="taskName">{{'todoDemo.editor.Name' | translate}}:</label>
                                <div class="col-md-9">
                                    <input appAutofocus type="text" id="taskName" name="taskName" placeholder="Enter task name" class="form-control" [ngClass]="{'is-valid': f.submitted && taskName.valid, 'is-invalid' : f.submitted && !taskName.valid}"
                                           [(ngModel)]="taskEdit.name" #taskName="ngModel" required />
                                    <span *ngIf="f.submitted && !taskName.valid" class="invalid-feedback">
                                        {{'todoDemo.editor.TaskNameRequired' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row description-form-group">
                                <label class="col-form-label col-md-3" for="taskDescription">{{'todoDemo.editor.Description' | translate}}:</label>
                                <div class="col-md-9">
                                    <input type="text" id="taskDescription" name="taskDescription" placeholder="Enter task description" class="form-control" [(ngModel)]="taskEdit.description" />
                                </div>
                            </div>

                            <div class="row">
                                <label class="col-form-label col-md-3"> </label>
                                <div class="col-md-9">
                                    <div class="form-check">
                                        <input class="form-check-input" id="isImportant" name="isImportant" type="checkbox" [(ngModel)]="taskEdit.important">
                                        <label for="isImportant" class="form-check-label">{{'todoDemo.editor.Important' | translate}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <hr class="edit-last-separator-hr" />
                                </div>
                            </div>


                            <div class="form-group actionBtn-form-group">
                                <div class="float-right">
                                    <button type="submit" class="btn btn-primary">{{'todoDemo.editor.AddTask' | translate}}</button>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>