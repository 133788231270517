<div>
    <div class="row control-box">
        <div class="col-lg-8">
        </div>
        <div class="col-lg-4">
            <ul class="nav flex-column flex-lg-row float-right">
                <li class="nav-item toolbaritem">
                    <a class="nav-link" href="javascript:;" (click)="addClub()"><i class="fa fa-plus"></i> {{'club.AddClub' | translate}}</a>
                </li>
            </ul>
        </div>
    </div>

    <ngx-datatable class="material colored-header sm table table-striped table-hover"
                   [loadingIndicator]="loadingIndicator"
                   [rows]="clubRows"
                   [rowHeight]="35"
                   [headerHeight]="35"
                   [footerHeight]="35"
                   [columns]="clubsColumns"
                   [columnMode]="'force'"
                   [count]="clubsTotalCount"
                   [limit]="20">
    </ngx-datatable>


    <ng-template #daneTemplate let-row="row" let-value="value">
        <span class="inline-label">
            {{value}}
        </span>
    </ng-template>



    <ng-template #disciplinesTemplate let-row="row" let-value="value" let-i="index">
        <span class="badge badge-pill badge-secondary" *ngFor="let discipline of value">{{discipline.disciplineName}}</span>
    </ng-template>



    <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
        <div>
            <a class="btn btn-link btn-sm" href="javascript:;" (click)="editClub(value)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> {{'club.Edit' | translate}}</a>|
            <a class="btn btn-link btn-sm" href="javascript:;" (click)="deleteClub(value)"><i class="fa fa-trash-o" aria-hidden="true"></i> {{'club.Delete' | translate}}</a>
        </div>
    </ng-template>


    <div class="modal fade" bsModal #clubEditorModal="bs-modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 *ngIf="!editingClub" class="modal-title float-left"><i class="fa fa-tasks"></i> {{'club.AddClub' | translate}}</h4>
                    <h4 *ngIf="editingClub" class="modal-title float-left"><i class="fa fa-tasks"></i> {{'club.EditClub' | translate}}</h4>

                    <button type="button" class="close" title="Close" (click)="clubEditorModal.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form *ngIf="formResetToggle" name="clubEditorForm" #f="ngForm" novalidate autocomplete="off" (ngSubmit)="f.form.valid ? saveClub() :  (!clubName.valid && showErrorAlert('Club name is required', 'Please enter a club name.')); ">
                        <div class="form-group row">
                            <label class="col-form-label col-md-3" for="clubName">{{'club.ClubName' | translate}}:</label>
                            <div class="col-md-9">
                                <input type="text" id="clubName" name="clubName" placeholder="Enter club name" class="form-control" [ngClass]="{'is-valid': f.submitted && clubName.valid, 'is-invalid' : f.submitted && !clubName.valid}" [(ngModel)]="newClub.clubName" #clubName="ngModel" required />
                                <span *ngIf="f.submitted && !clubName.valid" class="invalid-feedback">
                                    {{'club.ClubNameRequired' | translate}}
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <hr class="edit-separator-hr" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-form-label col-md-3" for="disciplines-club-info">{{'club.Disciplines' | translate}}</label>
                            <div class="col-lg-9">
                                <ng-select id="disciplines-club-info" [ngClass]="{'is-valid': f.submitted && disciplines.valid, 'is-invalid' : f.submitted && !disciplines.valid}" name="disciplines" [items]="allDisciplines" bindLabel="disciplineName" bindValue="id" [multiple]="true" [closeOnSelect]="false"
                                           placeholder="Select Disciplines" [(ngModel)]="newClub.disciplines" #disciplines="ngModel" class="form-control left-status pill-items" required>
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span title='{{item.disciplineName}}' class='badge badge-pill badge-secondary'>{{item.disciplineName}}<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">�</span></span>
                                    </ng-template>

                                    <ng-template ng-option-tmp let-item="item">
                                        <span title='{{item.disciplineName}}' class='badge badge-pill badge-secondary'>{{item.disciplineName}}</span>
                                        <i class="fa fa-check d-none"></i>
                                    </ng-template>
                                </ng-select>
                                <span *ngIf="f.submitted && !disciplines.valid" class="invalid-feedback">
                                    {{'club.DisciplinesRequired' | translate}}
                                </span>
                            </div>
                        </div>















                        <div class="form-group actionBtn-form-group">
                            <div class="float-right">
                                <button type="submit" class="btn btn-primary">{{'club.Save' | translate}}</button>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
