import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, DialogType, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { AccountService } from '../../../../services/account.service';
import { Utilities } from '../../../../services/utilities';
import { Club } from '../../../../models/club.model';
import { ClubDiscipline } from '../../../../models/club-discipline.model';
import { ClubService } from '../../../../services/club.service';
import { Discipline } from '../../../../models/discipline.model';
import { DisciplineService } from '../../../../services/discipline.service';



@Component({
    selector: 'app-clubs-management',
    templateUrl: './clubs-management.component.html',
    styleUrls: ['./clubs-management.component.scss']
})
export class ClubsManagementComponent implements OnInit {

    clubRows: Club[] = [];
    clubsTotalCount: number = 0;
    allDisciplines: Discipline[] = [];
    editingClub = false;
    newClub: Club = new Club();
    clubsColumns = [];
    loadingIndicator: boolean;
    formResetToggle = true;

    @ViewChild('f')
    public form;

    @ViewChild('clubName')
    public clubName;


    @ViewChild('daneTemplate', { static: true })
    daneTemplate: TemplateRef<any>;

    @ViewChild('actionsTemplate', { static: true })
    actionsTemplate: TemplateRef<any>;


    @ViewChild('disciplinesTemplate', { static: true })
    disciplinesTemplate: TemplateRef<any>;


    @ViewChild('clubEditorModal', { static: true })
    clubEditorModal: ModalDirective;





    constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, private clubService: ClubService, private disciplineService: DisciplineService) {
    }


    ngOnInit() {

        const gT = (key: string) => this.translationService.getTranslation(key);

        this.clubsColumns = [
            { prop: 'clubName', name: gT('club.ClubName'), cellTemplate: this.daneTemplate },
            { prop: 'clubDisciplines', name: gT('club.Disciplines'), width: 120, cellTemplate: this.disciplinesTemplate },
            { prop: 'id', name: gT('club.Action'), cellTemplate: this.actionsTemplate, width: 180, canAutoResize: false }
        ];

        this.loadData();
    }




    loadData() {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;
        this.clubService.getAllClubs().subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));
        this.disciplineService.getAllDisciplines().subscribe(results => this.allDisciplines = results , error => this.onDataLoadFailed(error));
    }


    onDataLoadSuccessful(clubs: Club[]) {
        clubs.forEach(val => {
            val.disciplines = [];
            val.clubDisciplines.forEach(a => val.disciplines.push(a.disciplineId));
        });
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.clubRows = clubs;
        this.clubsTotalCount = this.clubRows.length;
    }


    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }





    addClub() {
        this.editingClub = false;
        this.formResetToggle = false;
        this.formResetToggle = true;
        this.newClub = new Club();
        this.clubEditorModal.show();

    }

    editClub(id: number) {
        this.editingClub = true;
        this.formResetToggle = false;
        this.formResetToggle = true;
        this.newClub = this.clubRows.find(a => a.id == id);
        this.clubEditorModal.show();

    }



    deleteClub(id: number) {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;
        this.clubService.deleteClubById(id).subscribe(results => {
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }


    saveClub() {
        this.alertService.startLoadingMessage('Saving changes...');
        this.loadingIndicator = true;
        if (this.newClub.id > 0) {
            this.clubService.editClub(this.newClub).subscribe(results => {
                this.resetForm();
                this.loadData();
            }, error => this.onDataLoadFailed(error));
        }
        else {
            this.clubService.createClub(this.newClub).subscribe(results => {
                this.resetForm();
                this.loadData();
            }, error => this.onDataLoadFailed(error));
        }
    }



    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }



    resetForm(replace = false) {
        this.alertService.stopLoadingMessage();
        this.clubEditorModal.hide();
        this.newClub = new Club();
        if (!replace) {
            this.form.reset();
        } else {
            this.formResetToggle = false;

            setTimeout(() => {
                this.formResetToggle = true;
            });
        }
    }



}
