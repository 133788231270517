import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { fadeInOut } from '../../../../services/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { Utilities } from '../../../../services/utilities';
import { Discipline } from '../../../../models/discipline.model';
import { DisciplineService } from '../../../../services/discipline.service';


@Component({
    selector: 'app-disciplines-management',
    templateUrl: './disciplines-management.component.html',
    styleUrls: ['./disciplines-management.component.scss'],
    animations: [fadeInOut]
})
export class DisciplinesManagmentComponent implements OnInit {


    disciplineRows: Discipline[] = [];
    disciplineTotalCount: number = 0;

    editingDiscipline = false;

    newDiscipline: Discipline = new Discipline();


    formResetToggle = true;


    @ViewChild('disciplineEditorModal', { static: true })
    disciplineEditorModal: ModalDirective;


    @ViewChild('f')
    public form;


    @ViewChild('disciplineName')
    public disciplineName;


    @ViewChild('daneTemplate', { static: true })
    daneTemplate: TemplateRef<any>;

    @ViewChild('actionsTemplate', { static: true })
    actionsTemplate: TemplateRef<any>;


    disciplineColumns = [];
    loadingIndicator: boolean;

    constructor(private router: Router, private alertService: AlertService, private translationService: AppTranslationService, private disciplineService: DisciplineService, private route: ActivatedRoute, private location: Location) { }


    ngOnInit(): void {

        const gT = (key: string) => this.translationService.getTranslation(key);

        this.disciplineColumns = [
            { prop: 'disciplineName', name: gT('discipline.DisciplineName'), cellTemplate: this.daneTemplate },
            { prop: 'id', name: gT('discipline.Action'), cellTemplate: this.actionsTemplate, width: 180, canAutoResize: false }
        ];

        this.loadData();
    }




    private loadData() {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;
        this.disciplineService.getAllDisciplines().subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));
    }



    private onDataLoadSuccessful(disciplines: Discipline[]) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.disciplineRows = disciplines;
         this.disciplineTotalCount = this.disciplineRows.length;
    }


    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }

    addDiscipline() {
        this.editingDiscipline = false;
        this.formResetToggle = false;
        this.formResetToggle = true;
        this.newDiscipline = new Discipline();
        this.disciplineEditorModal.show();

    }

    editDiscipline(id: number) {
        this.editingDiscipline = true;
        this.formResetToggle = false;
        this.formResetToggle = true;
        this.newDiscipline = this.disciplineRows.find(a => a.id == id);
        this.disciplineEditorModal.show();

    }



    deleteDiscipline(id: number) {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;
        this.disciplineService.deleteDisciplineById(id).subscribe(results => {
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }


    saveDiscipline() {
        this.alertService.startLoadingMessage('Saving changes...');
        this.loadingIndicator = true;
        if (this.newDiscipline.id > 0) {
            this.disciplineService.editDiscipline(this.newDiscipline).subscribe(results => {
                this.resetForm();
                this.loadData();
            }, error => this.onDataLoadFailed(error));
        }
        else {
            this.disciplineService.createDiscipline(this.newDiscipline).subscribe(results => {
                this.resetForm();
                this.loadData();
            }, error => this.onDataLoadFailed(error));
        }
    }



    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }



    resetForm(replace = false) {
        this.alertService.stopLoadingMessage();
        this.disciplineEditorModal.hide();
        this.newDiscipline = new Discipline();
        if (!replace) {
            this.form.reset();
        } else {
            this.formResetToggle = false;

            setTimeout(() => {
                this.formResetToggle = true;
            });
        }
    }


}