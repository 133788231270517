<div>
    <div class="row control-box">
        <div class="col-lg-8">
            <div class="form-group search-box">
                <app-search-box (searchChange)="onSearchChanged($event)" placeholder="{{'tournament.Search' | translate}}"></app-search-box>
            </div>
        </div>
    </div>
    <div class="row row-cols-1 row-cols-md-3">
        <div  *ngFor="let row of rows" class="col mb-4">
            <div class="card  h-100">
                <a routerLink="/tournament/{{row.id}}">
                    <img class="card-img-top" [src]="row.logo" [alt]="row.tournamentName">
                    <div class="card-body">
                        <h5 class="card-title">{{row.tournamentName}}</h5>
                        <p class="card-text">{{row.disciplineName}}</p>
                        <p class="card-text">{{row.description}}</p>
                        <p class="card-text"><small class="text-muted">{{row.place +' ' + (row.dateStart  | date:'dd-MM-yyyy') }}</small></p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
