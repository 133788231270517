<div class="d-flex h-90" [@fadeInOut]>
    <div class="login-container m-auto">
        <div class="card boxshadow">
            <div class="card-header bg-primary dark text-white clearfix">
                <i class="fa fa-lock" aria-hidden="true"></i>  {{'mainMenu.Register' | translate}}
            </div>
            <div class="card-body">
                <div class="col-md-8 offset-md-2">
                    <form *ngIf="formResetToggle" name="userInfoForm" #f="ngForm" novalidate autocomplete="off"
                          (ngSubmit)="isSended ? save() : f.form.valid ? send() :
                        (userPassword && !userPassword.valid && showErrorAlert('Password is required', 'Please enter the current password'));
                        (email && email.errors?.required && showErrorAlert('Email is required', 'Please enter an email address (maximum of 200 characters)'));
                        (email && email.errors?.pattern && showErrorAlert('Invalid Email', 'Please enter a valid email address'));
                        (newPassword && !newPassword.valid && showErrorAlert('New password is required', 'Please enter the new password (minimum of 6 characters)'));
                        (newPassword && confirmPassword && newPassword.valid && confirmPassword.errors?.required && showErrorAlert('Confirmation password is required', 'Please enter the confirmation password'));
                        (newPassword && confirmPassword && newPassword.valid && confirmPassword.errors?.appValidateEqual && showErrorAlert('Passwword mismatch', 'New password and confirmation password do not match'));
                        (canAssignRoles && !roles.valid && showErrorAlert('Roles is required', 'Please select a minimum of 1 role'));">




                        <div *ngIf="!isSended">
                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="fullName">{{'users.editor.FullName' | translate}}</label>
                                <div class="col-md-9">
                                    <input type="text" attr.id="fullName" name="fullName" placeholder="Enter full name" class="form-control" [(ngModel)]="userEdit.fullName" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>


                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="email">{{'users.editor.Email' | translate}}</label>
                                <div class="col-md-9">
                                    <input type="text" attr.id="email" name="email" placeholder="Enter email address" class="form-control" [ngClass]="{'is-valid': f.submitted && email.valid, 'is-invalid' : f.submitted && !email.valid}"
                                           [(ngModel)]="userEdit.email" #email="ngModel" required maxlength="200" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" />
                                    <span *ngIf="showValidationErrors && f.submitted && email.errors?.required" class="invalid-feedback">
                                        {{'users.editor.EmailRequired' | translate}}
                                    </span>
                                    <span *ngIf="showValidationErrors && f.submitted && email.errors?.pattern" class="invalid-feedback">
                                        {{'users.editor.InvalidEmail' | translate}}
                                    </span>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>

                            <div class="form-group row">

                                <label class="col-form-label col-md-3" for="newPassword">{{'users.editor.Password' | translate}}</label>
                                <div class="col-md-9">


                                    <input type="password" attr.id="newPassword" name="newPassword" placeholder="Enter new password" class="form-control"
                                           [ngClass]="{'is-valid': f.submitted && newPassword.valid, 'is-invalid' : f.submitted && !newPassword.valid}" [(ngModel)]="userEdit.newPassword" #newPassword="ngModel"
                                           required minlength="6" appValidateEqual="confirmPassword" reverse="true" />
                                    <span *ngIf="showValidationErrors && f.submitted && !newPassword.valid" class="invalid-feedback">
                                        {{'users.editor.NewPasswordRequired' | translate}}
                                    </span>

                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="confirmPassword"> </label>
                                <div class="col-md-9">
                                    <input type="password" attr.id="confirmPassword" name="confirmPassword" placeholder="Confirm new password" class="form-control"
                                           [ngClass]="{'is-valid': f.submitted && confirmPassword.valid, 'is-invalid' : f.submitted && !confirmPassword.valid}" [(ngModel)]="userEdit.confirmPassword" #confirmPassword="ngModel"
                                           required appValidateEqual="newPassword" />
                                    <span *ngIf="showValidationErrors && f.submitted && confirmPassword.errors?.required" class="invalid-feedback">
                                        {{'users.editor.ConfirmationPasswordRequired' | translate}}
                                    </span>
                                    <span *ngIf="showValidationErrors && f.submitted && confirmPassword.errors?.appValidateEqual && !confirmPassword.errors?.required" class="invalid-feedback">
                                        {{'users.editor.PasswordMismatch' | translate}}
                                    </span>
                                </div>


                            </div>





                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>



                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="phoneNumber">{{'users.editor.PhoneNumber' | translate}}</label>
                                <div class="col-md-9">
                                    <input type="text" attr.id="phoneNumber" name="phoneNumber" placeholder="Enter phone number" class="form-control" [ngClass]="{'is-valid': f.submitted && phoneNumber.valid, 'is-invalid' : f.submitted && !phoneNumber.valid}" [(ngModel)]="userEdit.phoneNumber" #phoneNumber="ngModel" required maxlength="200" pattern="^\++[0-9]{11}" />
                                    <span *ngIf="showValidationErrors && f.submitted && phoneNumber.errors?.required" class="invalid-feedback">
                                        {{'users.editor.PhoneNumberRequired' | translate}}
                                    </span>
                                    <span *ngIf="showValidationErrors && f.submitted && phoneNumber.errors?.pattern" class="invalid-feedback">
                                        {{'users.editor.InvalidPhoneNumber' | translate}}
                                    </span>
                                </div>
                            </div>




                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-5">

                                </div>
                                <div class="col-sm-7">
                                    <div ngPreserveWhitespaces class="float-right">
                                        <button type="button" (click)="close()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
                                        <button type="submit" class="btn btn-primary" [disabled]="isSaving">
                                            <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Next' | translate)}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div *ngIf="isSended">
                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="codeEmail">{{'users.editor.CodeEmail' | translate}}</label>
                                <div class="col-md-9">
                                    <input type="text" attr.id="codeEmail" name="codeEmail" placeholder="Enter Email code" class="form-control" [ngClass]="{'is-valid': f.submitted && codeEmail.valid, 'is-invalid' : f.submitted && !codeEmail.valid}"
                                           [(ngModel)]="userEdit.codeEmail" #codeEmail="ngModel" required />
                                    <span *ngIf="showValidationErrors && f.submitted && codeEmail.errors?.required" class="invalid-feedback">
                                        {{'users.editor.CodeEmailRequired' | translate}}
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>


                            <div class="form-group row">
                                <label class="col-form-label col-md-3" for="codeSms">{{'users.editor.CodeSms' | translate}}</label>
                                <div class="col-md-9">
                                    <input type="text" attr.id="codeSms" name="codeSms" placeholder="Enter SMS code" class="form-control" [ngClass]="{'is-valid': f.submitted && codeSms.valid, 'is-invalid' : f.submitted && !codeSms.valid}"
                                           [(ngModel)]="userEdit.codeSms" #codeSms="ngModel" required />
                                    <span *ngIf="showValidationErrors && f.submitted && codeSms.errors?.required" class="invalid-feedback">
                                        {{'users.editor.CodeSmsRequired' | translate}}
                                    </span>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-lg-12">
                                    <hr class="edit-separator-hr" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-5">

                                </div>
                                <div class="col-sm-7">
                                    <div ngPreserveWhitespaces class="float-right">
                                        <button type="button" (click)="close()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
                                        <button type="submit" class="btn btn-primary" [disabled]="isSaving">
                                            <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Next' | translate)}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
