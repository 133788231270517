import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class ClubEndpoint extends EndpointBase {

    get clubsUrl() { return this.configurations.baseUrl + '/api/admin/club/all'; }
    get clubUrl() { return this.configurations.baseUrl + '/api/admin/club'; }

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService);
    }



    getAllClubsEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.clubsUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getAllClubsEndpoint());
            }))
    }



    deleteClubByIdEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.clubUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.deleteClubByIdEndpoint(id));
            }));
    }


    createClubEndpoint<T>(club: any): Observable<T> {
        return this.http.post<T>(this.clubUrl, JSON.stringify(club), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.createClubEndpoint(club));
            }));
    }


    editClubEndpoint<T>(club: any): Observable<T> {

        return this.http.put<T>(this.clubUrl, JSON.stringify(club), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.createClubEndpoint(club));
            }));
    }

}
