import { TournamentCompetition } from './tournament-competitions.model';

export class Tournament {
    constructor() {
        this.tournamentCompetitions = [];
        this.dateRange = [];
    }
    id: number;

    tournamentName: string;
    place: string;
    entryFee: number;
    disciplineId: number;
    logo: string;
    description: string;
    disciplineName: string;

    dateStart: Date;
    dateEnd: Date;
    dateRange: Date[];

    tournamentCompetitions: TournamentCompetition[]
}
