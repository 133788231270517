import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { fadeInOut } from '../../../../services/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { TournamentService } from '../../../../services/tournament.service';
import { Utilities } from '../../../../services/utilities';
import { TournamentApplication } from '../../../../models/tournament-application.model';



@Component({
    selector: 'app-tournament-players',
    templateUrl: './tournament-players.html',
    styleUrls: ['./tournament-players.scss'],
    animations: [fadeInOut]
})
export class TournamentPlayersComponent implements OnInit {


    @Input()
    tournamentId: number;

    applicationRows: TournamentApplication[] = [];
    applicationTotalCount: Number = 0;
 
    newApplication: TournamentApplication = new TournamentApplication();


    formResetToggle = true;

 
    @ViewChild('daneTemplate', { static: true })
    daneTemplate: TemplateRef<any>;

 
    @ViewChild('dateTemplate', { static: true })
    dateTemplate: TemplateRef<any>;

    @ViewChild('actionTemplate', { static: true })
    actionTemplate: TemplateRef<any>;


    applicationColumns = [];


    constructor(private router: Router, private alertService: AlertService, private translationService: AppTranslationService, private tournamentService: TournamentService, private route: ActivatedRoute, private location: Location) { }


    ngOnInit(): void {

        const gT = (key: string) => this.translationService.getTranslation(key);

        this.applicationColumns = [
            { prop: 'playerName', name: gT('application.PlayerName'), width: 200, cellTemplate: this.daneTemplate },
            { prop: 'dateOfBirth', name: gT('application.DateOfBirth'), width: 70, cellTemplate: this.dateTemplate },
            { prop: 'competitionName', name: gT('application.Competition'), cellTemplate: this.daneTemplate },
            { prop: 'id', name: gT('application.Action'), cellTemplate: this.actionTemplate, width: 100, canAutoResize: false }
        ];

        this.loadData();
    }


    private loadData() {
        this.alertService.startLoadingMessage();
        this.tournamentService.getTournamentApplications(this.tournamentId).subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));
    }


    private onDataLoadSuccessful(tournamentApplications: TournamentApplication[]) {
        this.alertService.stopLoadingMessage();
        this.applicationRows = tournamentApplications;
        this.applicationTotalCount = this.applicationRows.length;
    }


    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


    deleteApplication(id: number) {
        this.alertService.startLoadingMessage();
        this.tournamentService.deleteTournamentApplicationById(id).subscribe(results => {
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }



    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }




}
