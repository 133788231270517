import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { fadeInOut } from '../../../../services/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { TournamentService } from '../../../../services/tournament.service';
import { Utilities } from '../../../../services/utilities';
import { TournamentManagement } from '../../../../models/tournament-management.model';


@Component({
    selector: 'app-tournament-judges',
    templateUrl: './tournament-judges.html',
    styleUrls: ['./tournament-judges.scss'],
    animations: [fadeInOut]
})
export class TournamentJudgesComponent implements OnInit {


    @Input()
    tournamentId: number;

    judgesRows: TournamentManagement[] = [];
    judgesTotalCount: Number = 0;
 
    newJudge: TournamentManagement = new TournamentManagement();


    formResetToggle = true;


    @ViewChild('judgesEditorModal', { static: true })
   judgesEditorModal: ModalDirective;


    @ViewChild('f')
    public form;

 
    @ViewChild('daneTemplate', { static: true })
    daneTemplate: TemplateRef<any>;

    @ViewChild('actionTemplate', { static: true })
    actionTemplate: TemplateRef<any>;


    judgesColumns = [];


    constructor(private router: Router, private alertService: AlertService, private translationService: AppTranslationService, private tournamentService: TournamentService, private route: ActivatedRoute, private location: Location) { }


    ngOnInit(): void {

        const gT = (key: string) => this.translationService.getTranslation(key);

        this.judgesColumns = [
            { prop: 'userName', name: gT('judge.Email'), width: 200,  cellTemplate: this.daneTemplate },
            { prop: 'fullName', name: gT('judge.FullName'), cellTemplate: this.daneTemplate },
            { prop: 'id', name: gT('judge.Action'), cellTemplate: this.actionTemplate, width: 100, canAutoResize: false }
        ];

        this.loadData();
    }




    private loadData() {
        this.alertService.startLoadingMessage();
        this.tournamentService.getTournamentJudges(this.tournamentId).subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));
    }

   

    private onDataLoadSuccessful(tournamentJudges: TournamentManagement[]) {
        this.alertService.stopLoadingMessage();
        this.judgesRows = tournamentJudges;
        this.judgesTotalCount = this.judgesRows.length;
    }


    private onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
    }


    deleteJudge(id: number) {
        this.alertService.startLoadingMessage();
        this.tournamentService.deleteTournamentJudgeById(id).subscribe(results => {
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }



    addJudge() {
        this.formResetToggle = false;
        this.formResetToggle = true;
        this.judgesEditorModal.show();

    }

    saveJudge() {
        this.alertService.startLoadingMessage('Saving changes...');
        this.newJudge.tournamentId = this.tournamentId;
        this.tournamentService.createTournamentJudge(this.newJudge).subscribe(results => {
            this.resetForm();
            this.loadData();
        }, error => this.onDataLoadFailed(error));
    }

    showErrorAlert(caption: string, message: string) {
        this.alertService.showMessage(caption, message, MessageSeverity.error);
    }



    resetForm(replace = false) {
        this.alertService.stopLoadingMessage();
        this.judgesEditorModal.hide();
        this.newJudge = new TournamentManagement();
        if (!replace) {
            this.form.reset();
        } else {
            this.formResetToggle = false;

            setTimeout(() => {
                this.formResetToggle = true;
            });
        }
    }






}
