import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class TournamentEndpoint extends EndpointBase {

    get allTournamentsUrl() { return this.configurations.baseUrl + '/api/home/tournament/all'; }
    get tournamentByIdUrl() { return this.configurations.baseUrl + '/api/home/tournament'; }
    get playerTournamentsUrl() { return this.configurations.baseUrl + '/api/player/tournament'; }
    get organizerTournamentsUrl() { return this.configurations.baseUrl + '/api/organizer/tournament'; }
    get managerTournamentsUrl() { return this.configurations.baseUrl + '/api/manager/tournament'; }
    get judgeTournamentsUrl() { return this.configurations.baseUrl + '/api/judge/tournament'; }

    get applicationTournamentsUrl() { return this.configurations.baseUrl + '/api/player/tournament/application'; }
    get playerUrl() { return this.configurations.baseUrl + '/api/player/player'; }
    get clubUrl() { return this.configurations.baseUrl + '/api/player/club'; }

    get disciplinesUrl() { return this.configurations.baseUrl + '/api/organizer/discipline/all'; }
    get tournamentsUrl() { return this.configurations.baseUrl + '/api/organizer/tournament'; }
    get competitionUrl() { return this.configurations.baseUrl + '/api/organizer/competition'; }
    get tournamentsCompetitionUrl() { return this.configurations.baseUrl + '/api/organizer/tournament/competition'; }
    get tournamentsManagementUrl() { return this.configurations.baseUrl + '/api/organizer/tournament/management'; }
    get tournamentsJudgeUrl() { return this.configurations.baseUrl + '/api/organizer/tournament/judge'; }
    get tournamentsApplicationUrl() { return this.configurations.baseUrl + '/api/organizer/tournament/applications'; }
    get tournamentsPhaseUrl() { return this.configurations.baseUrl + '/api/organizer/tournament/phase'; }






    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService);
    }


    getAllTournamentsEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.allTournamentsUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getAllTournamentsEndpoint());
            }));
    }



    getPlayerTournamentsEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.playerTournamentsUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getPlayerTournamentsEndpoint());
            }));
    }
    getOrganizerTournamentsEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.organizerTournamentsUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getOrganizerTournamentsEndpoint());
            }));
    }
    getManagerTournamentsEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.managerTournamentsUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getManagerTournamentsEndpoint());
            }));
    }
    getJudgeTournamentsEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.judgeTournamentsUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getJudgeTournamentsEndpoint());
            }));
    }

    getTournamentByIdEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentByIdUrl}/${id}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getTournamentByIdEndpoint(id));
            }));
    }


    createApplicationEndpoint<T>(application: any): Observable<T> {
        return this.http.post<T>(this.applicationTournamentsUrl, JSON.stringify(application), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.createApplicationEndpoint(application));
            }));
    }


    createTournamentCompetitionEndpoint<T>(tournamentCompetition: any): Observable<T> {
        return this.http.post<T>(this.tournamentsCompetitionUrl, JSON.stringify(tournamentCompetition), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.createTournamentCompetitionEndpoint(tournamentCompetition));
            }));
    }




    createTournamentEndpoint<T>(tournament: any): Observable<T> {
        return this.http.post<T>(this.tournamentsUrl, JSON.stringify(tournament), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.
                    createTournamentEndpoint(tournament));
            }));
    }
    updateTournamentEndpoint<T>(tournament: any): Observable<T> {
        return this.http.put<T>(this.tournamentsUrl, JSON.stringify(tournament), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.
                    updateTournamentEndpoint(tournament));
            }));
    }






    getUserPlayersEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.playerUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getUserPlayersEndpoint());
            }));
    }


    getDisciplineClubsEndpoint<T>(discipline: number): Observable<T> {
        const endpointUrl = `${this.clubUrl}?discipline=${discipline}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getDisciplineClubsEndpoint(discipline));
            }));
    }


    getDisciplinesEndpoint<T>(): Observable<T> {
        return this.http.get<T>(this.disciplinesUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getDisciplinesEndpoint());
            }))
    }



    getDisciplineCompetitionsEndpoint<T>(tournamentId: number): Observable<T> {
        const endpointUrl = `${this.competitionUrl}?tournamentId=${tournamentId}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getDisciplineClubsEndpoint(tournamentId));
            }))
    }



    deleteTournamentCompetitionByIdEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsCompetitionUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.deleteTournamentCompetitionByIdEndpoint(id));
            }));
    }



    getTournamentCompetitionsEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsCompetitionUrl}/${id}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getTournamentCompetitionsEndpoint(id));
            }));
    }





    getTournamentManagementsEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsManagementUrl}/${id}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getTournamentManagementsEndpoint(id));
            }));
    }



    deleteTournamentManagementByIdEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsManagementUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.deleteTournamentManagementByIdEndpoint(id));
            }));
    }


    createTournamentManagementEndpoint<T>(tournamentManagement: any): Observable<T> {
        return this.http.post<T>(this.tournamentsManagementUrl, JSON.stringify(tournamentManagement), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.
                    createTournamentManagementEndpoint(tournamentManagement));
            }));
    }







    getTournamentJudgesEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsJudgeUrl}/${id}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getTournamentJudgesEndpoint(id));
            }));
    }



    deleteTournamentJudgeByIdEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsJudgeUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.deleteTournamentJudgeByIdEndpoint(id));
            }));
    }


    createTournamentJudgeEndpoint<T>(tournamentJudge: any): Observable<T> {
        return this.http.post<T>(this.tournamentsJudgeUrl, JSON.stringify(tournamentJudge), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.
                    createTournamentJudgeEndpoint(tournamentJudge));
            }));
    }







    getTournamentApplicationsEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsApplicationUrl}/${id}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getTournamentApplicationsEndpoint(id));
            }));
    }

    deleteTournamentApplicationByIdEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsApplicationUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.deleteTournamentApplicationByIdEndpoint(id));
            }));
    }







    getTournamentPhasesEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsPhaseUrl}/${id}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getTournamentPhasesEndpoint(id));
            }));
    }



    deleteTournamentPhaseByIdEndpoint<T>(id: number): Observable<T> {
        const endpointUrl = `${this.tournamentsPhaseUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.deleteTournamentPhaseByIdEndpoint(id));
            }));
    }


    createTournamentPhaseEndpoint<T>(tournamentJudge: any): Observable<T> {
        return this.http.post<T>(this.tournamentsPhaseUrl, JSON.stringify(tournamentJudge), this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.
                    createTournamentPhaseEndpoint(tournamentJudge));
            }));
    }

}
